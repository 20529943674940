import { useMediaQuery } from '@react-hook/media-query';

import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { userAgentSelector } from '../store';

export const useIsDesktop = () => {
  const isSSR = typeof document === 'undefined';
  const isDesktopServer = useSelector(userAgentSelector.isDesktop);
  const isDesktopClient = useMediaQuery('screen and (min-width:1024px)');

  const [isDesktop, setIsDesktop] = useState<boolean>(isDesktopServer);

  useEffect(() => {
    if (!isSSR) {
      setIsDesktop(isDesktopClient);
    }
  }, [isDesktopClient, isSSR]);

  return isDesktop;
};
