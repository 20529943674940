import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useGetAccounts } from '@root/modules/accounts/hooks/use-get-accounts';
import { CustomErrorCodes } from '@root/shared/ui/toast/custom-errors';

export const useCustomErrors = (customData) => {
  const { data } = useGetAccounts();
  const { t } = useTranslation('errors');

  const { errorId, message } = useMemo(() => {
    if (!customData) {
      return { errorId: null, message: null };
    }

    switch (customData.code) {
      case CustomErrorCodes.InvalidAccount: {
        const username = data?.find((account) => account.id === customData.accountId)?.username;
        return { message: t(customData.code, { userName: username }) };
      }

      case CustomErrorCodes.ReachedExpertsLimit:
      case CustomErrorCodes.WrongAccountCredentials:
      case CustomErrorCodes.ReachedAccountsLimit:
      case CustomErrorCodes.AccountAlreadyExist: {
        return { message: t(customData.code) };
      }

      case CustomErrorCodes.UnhandledException: {
        return { errorId: customData.payload.errorId, message: t(customData.code, { requestId: customData.payload.errorId }) };
      }

      default: {
        return { errorId: null, message: null };
      }
    }
  }, [customData, data, t]);

  return { message, errorId };
};
