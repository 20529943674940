import styled from 'styled-components';

import clsx from 'clsx';
import { FC, useRef, useState } from 'react';
import { PopoverPosition } from 'react-tiny-popover';

import { useOutsideClick } from '@root/shared/hooks/use-outside-click';

type TooltipProps = {
  children: React.ReactNode;
  content: React.ReactNode;
  positions?: PopoverPosition[];
  wrapperClassName?: string;
  keepOnMouseLeave?: boolean;
  arrowSize?: number;
  padding?: number;
  inline?: boolean;
};

export const Container = styled.div`
  background-color: ${({ theme }) => theme.gray[800]};
  box-shadow: 0px 10px 24px rgba(12, 12, 12, 0.6);
  max-width: 300px;
  bottom: 50px;
  left: 0;
`;

const Arrow = styled.div`
  position: absolute;
  width: 10px;
  height: 10px;
  transform: rotate(45deg);
  background-color: ${({ theme }) => theme.gray[800]};
  bottom: -5px;
  z-index: -1;
  left: calc(50% - 5px);
`;

export const Tooltip: FC<TooltipProps> = ({ children, content, wrapperClassName = '' }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  const wrapperRef = useRef<HTMLDivElement>(null);

  useOutsideClick(wrapperRef, () => {
    setIsDropdownOpen(false);
  });

  return (
    <div className='relative' ref={wrapperRef} onMouseLeave={() => setIsDropdownOpen(false)}>
      <div className={clsx('cursor-pointer relative', wrapperClassName)} onMouseEnter={() => setIsDropdownOpen(true)}>
        {children}
      </div>
      {isDropdownOpen && (
        <Container className='absolute rounded px-2 py-2 z-50 flex justify-end items-center'>
          {content}
          <Arrow />
        </Container>
      )}
    </div>
  );
};
