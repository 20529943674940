import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import resourcesToBackend from 'i18next-resources-to-backend';
import { initReactI18next } from 'react-i18next';

export const supportedLanguages = ['en', 'de', 'es', 'fr', 'it', 'ja', 'no', 'pt', 'th', 'zh', 'sk', 'ar', 'vi'];

i18n
  .use(
    resourcesToBackend((language, namespace, callback) => {
      import(`../src/assets/locales/${language}/${namespace}.json`)
        .then((resources) => {
          callback(null, resources);
        })
        .catch((error) => {
          callback(error, null);
        });
    }),
  )
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    supportedLngs: supportedLanguages,
    debug: false,
    ns: [
      'verification-reminder',
      'user-settings',
      'user-greeting',
      'subscriptions',
      'sign-up',
      'sign-in',
      'settings',
      'reset-password',
      'overall-stats',
      'marketplace-trade-idea-providers',
      'marketplace-trade-idea-provider-details',
      'marketplace-trade-idea-modals',
      'forgot-password',
      'common',
      'auth-validation',
      'auth',
      'forex-accounts',
      'forex-common',
      'forex-experts',
      'forex-orders',
      'forex-signals',
      'forex-templates',
      'ea-guide',
      'two-fa',
      'errors',
    ],

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    detection: {
      order: ['cookie', 'navigator', 'path', 'subdomain'],
      caches: ['cookie'],
      lookupCookie: 'siteLang',
      cookieMinutes: 60 * 24 * 100, // 100 days
    },
    react: {
      useSuspense: true,
    },
    nsSeparator: ':',
  });

export default i18n;
