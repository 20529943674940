import { FC, SVGProps } from 'react';

import { baseIconProps } from './base-icon-props';

export const GiftIcon: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg {...baseIconProps} width='13' height='14' viewBox='0 0 13 14' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path fillRule='evenodd' clipRule='evenodd' d='M1.625 6.4585H11.375V12.9585H1.625V6.4585ZM2.70833 7.54183V11.8752H10.2917V7.54183H2.70833Z' fill='currentColor' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0.541687 4.29183C0.541687 3.69352 1.02671 3.2085 1.62502 3.2085H11.375C11.9733 3.2085 12.4584 3.69352 12.4584 4.29183V6.4585C12.4584 7.0568 11.9733 7.54183 11.375 7.54183H1.62502C1.02671 7.54183 0.541687 7.0568 0.541687 6.4585V4.29183ZM11.375 4.29183H1.62502V6.4585H11.375V4.29183Z'
        fill='currentColor'
      />
      <path fillRule='evenodd' clipRule='evenodd' d='M7.04165 4.021V12.6877H5.95831V4.021H7.04165Z' fill='currentColor' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5.10538 2.13826C5.05794 2.126 5.01753 2.125 4.875 2.125C4.57585 2.125 4.33333 2.36751 4.33333 2.66666C4.33333 2.96582 4.57585 3.20833 4.875 3.20833H5.96201C5.96162 3.20748 5.96122 3.20664 5.96082 3.20579C5.8684 3.01043 5.76294 2.81091 5.65127 2.63872C5.53543 2.46011 5.43283 2.34227 5.35496 2.28171C5.21708 2.17448 5.14274 2.14792 5.10538 2.13826ZM4.875 4.29166C3.97754 4.29166 3.25 3.56413 3.25 2.66666C3.25 1.7692 3.97754 1.04166 4.875 1.04166C4.88388 1.04166 4.89326 1.04163 4.90311 1.04159C5.01488 1.04116 5.1873 1.0405 5.37647 1.0894C5.5924 1.14521 5.79823 1.25405 6.02004 1.42655C6.23723 1.59547 6.41758 1.82935 6.56019 2.04924C6.58583 2.08878 6.61092 2.12892 6.63542 2.16941C6.65992 2.12892 6.685 2.08878 6.71065 2.04924C6.85326 1.82935 7.0336 1.59547 7.2508 1.42655C7.4726 1.25405 7.67843 1.14521 7.89436 1.0894C8.08353 1.0405 8.25595 1.04116 8.36772 1.04159C8.37757 1.04163 8.38695 1.04166 8.39583 1.04166C9.2933 1.04166 10.0208 1.7692 10.0208 2.66666C10.0208 3.56413 9.2933 4.29166 8.39583 4.29166H4.875ZM7.30882 3.20833H8.39583C8.69499 3.20833 8.9375 2.96582 8.9375 2.66666C8.9375 2.36751 8.69499 2.125 8.39583 2.125C8.2533 2.125 8.2129 2.126 8.16546 2.13826C8.1281 2.14792 8.05375 2.17448 7.91587 2.28171C7.838 2.34227 7.7354 2.46011 7.61956 2.63872C7.50789 2.81091 7.40243 3.01043 7.31002 3.20579C7.30962 3.20664 7.30922 3.20748 7.30882 3.20833Z'
        fill='currentColor'
      />
    </svg>
  );
};
