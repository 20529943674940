import styled, { css } from 'styled-components';

import clsx from 'clsx';
import React, { FC, useRef } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import infoImg from '@root/assets/images/cases/info.webp';
import { notionLinks } from '@root/infra/constants/links';
import { Image } from '@root/shared/ui/image';
import { Text } from '@root/shared/ui/typography';

type Props = {
  isFixed?: boolean;
  wrapperClassName?: string;
  textKey?: string;
  extraSpace?: boolean;
  withLink?: boolean;
};

const Wrapper = styled.div<Props>`
  ${(props) =>
    props.isFixed &&
    css`
      position: fixed;
      bottom: 20px;
      background: ${({ theme }) => theme.grayscale['700']};
      z-index: 2;
      padding: 16px;
      border-radius: 4px;
      box-shadow: 0px 10px 24px 0px rgba(12, 12, 12, 0.6);
      margin-right: 20px;
    `}
`;

const Disclaimer: FC<Props> = ({ textKey = 'disclaimer.presets', isFixed, wrapperClassName, extraSpace, withLink }) => {
  const { t } = useTranslation('common');

  return (
    <div>
      {extraSpace && (
        <Wrapper isFixed={false} className={clsx('-mt-4 flex items-center flex-col md:flex-row opacity-0 mr-4 sm:mr-5', wrapperClassName)}>
          <div className='flex items-center justify-between gap-2.5'>
            <div className='hidden md:block w-[28px] h-[28px] -mb-1 flex-shrink-0'>
              <Image src={infoImg} width={220} height={220} alt='warning' />
            </div>
            <span className='text-sm'>{t(textKey)}</span>
          </div>
          {withLink && (
            <Text size='sm' className='w-max px-5'>
              <Trans
                i18nKey={'common:learn_more'}
                components={{ a: <a className='text-primary-400 font-semibold whitespace-nowrap' target='_blank' rel='noreferrer' href={notionLinks.terms}></a> }}
              />
            </Text>
          )}
        </Wrapper>
      )}

      <Wrapper isFixed={isFixed} className={clsx('flex items-center flex-col md:flex-row', wrapperClassName)}>
        <div className='flex items-start justify-between gap-2.5'>
          <div className='hidden md:block w-[28px] h-[28px] -mb-1 flex-shrink-0'>
            <Image src={infoImg} width={220} height={220} alt='warning' />
          </div>
          <span className='text-sm'>{t(textKey)}</span>
        </div>
        {withLink && (
          <Text size='sm' className='w-max px-5'>
            <Trans
              i18nKey={'common:learn_more'}
              components={{
                a: <a className='text-primary-400 font-semibold whitespace-nowrap' target='_blank' rel='noreferrer' href={notionLinks.terms}></a>,
              }}
            />
          </Text>
        )}
      </Wrapper>
    </div>
  );
};

export default Disclaimer;
