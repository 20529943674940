import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';

import { GET_EXPERTS } from '@root/modules/experts/queries/get-experts.query';
import { authSelector } from '@root/shared-files/modules/auth/store';

export const useGetExperts = (enabled = true) => {
  const userId = useSelector(authSelector.userId);

  return useQuery(['experts'], GET_EXPERTS, {
    enabled: !!userId && enabled,
    staleTime: 1000 * 60 * 10,
  });
};
