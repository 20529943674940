import { FC, SVGProps } from 'react';

import { baseIconProps } from './base-icon-props';

export const BoldIcon: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg {...baseIconProps} viewBox='0 0 22 23' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g clipPath='url(#clip0_10573_34799)'>
        <path
          d='M11.8549 0.429688L11.9849 0.439687C12.5649 0.489688 13.0349 0.889687 13.1849 1.44969L13.2149 1.57969L13.3949 2.65969L13.4149 2.76969C13.6049 3.52969 14.3849 3.97969 15.1349 3.77969L15.2649 3.73969L16.2849 3.35969L16.4049 3.31969C16.9649 3.16969 17.5549 3.37969 17.8849 3.84969L17.9549 3.95969L19.3349 6.35969H17.9949L16.8549 4.38969L15.6749 4.82969L15.5349 4.87969C14.1149 5.31969 12.6149 4.45969 12.2749 3.00969L12.2449 2.84969L12.0349 1.58969H8.33493L8.12493 2.84969L8.09493 2.99969C7.77493 4.44969 6.27493 5.30969 4.85493 4.87969L4.69493 4.82969L3.50493 4.37969L1.65493 7.57969L2.63493 8.38969L2.75493 8.49969C3.83493 9.50969 3.83493 11.2197 2.76493 12.2297L2.62493 12.3497L1.65493 13.1597L3.50493 16.3597L4.69493 15.9097L4.83493 15.8597C5.09493 15.7797 5.36493 15.7397 5.62493 15.7397V16.8997C5.49493 16.8997 5.36493 16.9097 5.23493 16.9497L5.10493 16.9897L4.08493 17.3697L3.96493 17.4297C3.40493 17.5797 2.81493 17.3697 2.48493 16.8997L2.41493 16.7897L0.744928 13.8997L0.684928 13.7897C0.444928 13.2697 0.554928 12.6597 0.954928 12.2497L1.06493 12.1497L1.90493 11.4497L2.00493 11.3597C2.54493 10.8197 2.55493 9.93969 2.01493 9.38969L1.90493 9.28969L1.05493 8.58969L0.964928 8.50969C0.554928 8.10969 0.444928 7.49969 0.674928 6.96969L0.744928 6.83969L2.41493 3.94969L2.48493 3.83969C2.81493 3.36969 3.39493 3.15969 3.95493 3.29969L4.09493 3.33969L5.11493 3.71969L5.22493 3.75969C5.96493 3.98969 6.74493 3.54969 6.95493 2.78969L6.98493 2.64969L7.16493 1.56969L7.19493 1.44969C7.34493 0.889687 7.81493 0.489687 8.39493 0.429688L8.51493 0.429688H11.8549Z'
          fill='currentColor'
        />
        <path
          d='M21.3452 15.2388V17.2388C21.3452 18.6188 20.2252 19.7388 18.8452 19.7388H9.03519L11.0152 21.7188L10.3052 22.4288L7.4752 19.5988C7.2752 19.3988 7.2752 19.0888 7.4752 18.8888L10.3052 16.0588L11.0152 16.7688L9.03519 18.7488H18.8452C19.6752 18.7488 20.3452 18.0788 20.3452 17.2488V15.2488H21.3452V15.2388ZM18.3652 8.04883L21.1952 10.8788C21.3952 11.0788 21.3952 11.3888 21.1952 11.5888L18.3652 14.4188L17.6552 13.7088L19.6352 11.7288H9.8252C8.9952 11.7288 8.3252 12.3988 8.3252 13.2288V15.2288H7.3252V13.2288C7.3252 11.8488 8.4452 10.7288 9.8252 10.7288H19.6352L17.6552 8.74883L18.3652 8.04883Z'
          fill='currentColor'
          stroke='currentColor'
          strokeWidth='0.2'
        />
      </g>
      <defs>
        <clipPath id='clip0_10573_34799'>
          <rect width='20.89' height='22.14' fill='white' transform='translate(0.554688 0.429688)' />
        </clipPath>
      </defs>
    </svg>
  );
};
