import { CaseReducer, PayloadAction, createSlice } from '@reduxjs/toolkit';

import { UserSubscriptionInfo } from '../types/user-subscription-info';

export type ISubscriptionState = {
  userSubscriptionInfo: UserSubscriptionInfo | null;
};

export namespace SubscriptionActions {
  export type SetUserSubscriptionInfo = PayloadAction<UserSubscriptionInfo | null>;
}

export type SubscriptionsSliceReducer = {
  setUserSubscriptionInfo: CaseReducer<ISubscriptionState, SubscriptionActions.SetUserSubscriptionInfo>;
};

export const initialState: ISubscriptionState = {
  userSubscriptionInfo: null,
};

export const subscriptionsSlice = createSlice<ISubscriptionState, SubscriptionsSliceReducer, 'subscriptions'>({
  name: 'subscriptions',
  initialState,
  reducers: {
    setUserSubscriptionInfo: (state, action) => {
      state.userSubscriptionInfo = action.payload;
    },
  },
});
