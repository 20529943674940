import { useCallback, useMemo } from 'react';
import { useQueries } from 'react-query';
import { useSelector } from 'react-redux';

import { queryClient } from '@root/infra/query';
import { AccountStatus } from '@root/modules/accounts/enums';
import { useGetAccounts } from '@root/modules/accounts/hooks/use-get-accounts';
import { accountsSelector } from '@root/modules/accounts/store/accounts.selector';
import { OpenOrdersDtoMapper } from '@root/modules/orders/mappers/open-orders-dto.mapper';
import { getOpenOrdersService } from '@root/modules/orders/services/get-open-orders.service';
import { ordersSelector } from '@root/modules/orders/store/orders.selector';
import { IOrder } from '@root/modules/orders/types/orders';
import { quotesSelector } from '@root/modules/quotes/store/quotes.selector';
import { Id } from '@root/shared/utils/types';

export const useGetOpenOrders = ({ enabled, selectedBroker, mtType }) => {
  const { data: accounts } = useGetAccounts();
  const quotesData = useSelector(quotesSelector.quotesData);
  const balancesCurrency = useSelector(accountsSelector.balanceCurrency);
  const closedOrderTickets = useSelector(ordersSelector.closedOrderTickets);
  const socketOrdersByTicket = useSelector(ordersSelector.ordersByTicket);

  const filteredByMtTypeAccounts = mtType === undefined ? accounts : accounts?.filter((account) => account.mtType === mtType);

  const openOrdersQueries = useQueries(
    filteredByMtTypeAccounts
      ?.filter((item) => (selectedBroker === 'all' ? true : selectedBroker === item.id))
      .map(({ id, isSignedIn }) => ({
        queryKey: ['fx', id, 'open-orders'],
        queryFn: () => getOpenOrdersService({ accountId: id }),
        enabled: enabled && isSignedIn,
        keepPreviousData: true,
      })) ?? [],
  );

  const brokerIdByAccountId = useMemo(
    () =>
      filteredByMtTypeAccounts?.reduce(
        (acc, item) => ({
          ...acc,
          [item.id]: item.companyId,
        }),
        {},
      ),
    [accounts],
  );

  const apiOrdersByTicket = openOrdersQueries?.reduce((acc, query) => {
    const list = (query?.data?.status === 200 && (query?.data?.payload as Array<IOrder>)) || [];
    if (list.length) {
      list.forEach((order) => {
        acc[order.ticket] = order;
      });
    }
    return acc;
  }, {});

  const openOrders = Object.values<IOrder>({ ...apiOrdersByTicket, ...socketOrdersByTicket }).reduce((acc, order) => {
    const shouldPushBySelectedBroker = selectedBroker === 'all' ? true : selectedBroker === order?.accountId;
    const shouldPushByClosedOrderList = !closedOrderTickets.includes(order?.ticket);
    const shouldPushByMTType = mtType ? order.mtType === mtType : true;

    if (shouldPushBySelectedBroker && shouldPushByClosedOrderList && shouldPushByMTType) {
      if (order.isSocketOrder) {
        acc.push(
          OpenOrdersDtoMapper.toDomain(order, quotesData?.[brokerIdByAccountId?.[order?.accountId]]?.[order.symbol], order.accountId as string, balancesCurrency[order.accountId]),
        );
      } else {
        acc.push(OpenOrdersDtoMapper.addCurrentPrice(order, quotesData?.[brokerIdByAccountId?.[order?.accountId]]?.[order.symbol]));
      }
    }

    return acc;
  }, [] as IOrder[]);

  const invalidateOpenOrderQueries = useCallback(() => {
    accounts?.map((account) => {
      queryClient.invalidateQueries(['fx', account?.id as Id, 'open-orders'], { exact: true });
    }) ?? [];
  }, [accounts]);

  return { openOrders: enabled ? openOrders : [], invalidateOpenOrderQueries };
};
