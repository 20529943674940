import { FC, JSXElementConstructor } from 'react';
import { Link } from 'react-router-dom';

import { convertPermissionLimitToHumanReadable } from '@root/shared-files/modules/auth/helpers';
import { PageLinks } from '@root/shared/constants/pageLinks';
import { Text } from '@root/shared/ui/typography';

type Props = {
  Icon: JSXElementConstructor<{ width?: number; height?: number }>;
  title: string;
  link: PageLinks;
  value: number | undefined;
  limit: number | undefined;
};

export const LimitItem: FC<Props> = ({ Icon, title, link, value, limit }) => (
  <div className='w-full flex items-center justify-between gap-x-2'>
    <Link to={link} className='flex items-center gap-x-2 p-0 hover:text-primary-400'>
      <Icon width={20} height={20} />
      <Text size='sm'>{title}</Text>
    </Link>
    <Text className='font-medium'>
      {value ?? 0} / <span className='text-gray-600'>{convertPermissionLimitToHumanReadable(limit)}</span>
    </Text>
  </div>
);
