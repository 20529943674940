import { useCallback, useLayoutEffect, useRef } from 'react';

// useEvent it's a hook to define an event handler with an always-stable function identity.
// Spec is here https://github.com/reactjs/rfcs/blob/useevent/text/0000-useevent.md
export const useEvent = <T extends (...args: any[]) => any>(handler: T): T => {
  const handlerRef = useRef<T>(handler);

  useLayoutEffect(() => {
    handlerRef.current = handler;
  });

  return useCallback(
    ((...args) => {
      return handlerRef.current(...args);
    }) as T,
    [],
  );
};
