import clsx from 'clsx';
import { DetailedHTMLProps, FC, HTMLAttributes, LabelHTMLAttributes, ReactNode } from 'react';

export type LabelProps = DetailedHTMLProps<LabelHTMLAttributes<HTMLLabelElement>, HTMLLabelElement> & { disabled?: boolean };

export const Label: FC<LabelProps> = ({ className, ...props }) => {
  return <label className={clsx('block mb-1 text-sm min-h-[22px]', { 'opacity-50': props.disabled }, className)} {...props} />;
};

export type IconLabelProps = DetailedHTMLProps<HTMLAttributes<HTMLSpanElement>, HTMLSpanElement> & { disabled?: boolean; icon: ReactNode; wrapperClassName?: string };

export const IconLabel: FC<IconLabelProps> = ({ children, icon, ...props }) => {
  return (
    <span
      className={clsx(
        'mb-1 text-sm min-h-[22px] flex items-center',
        { 'opacity-50': props.disabled, [props.wrapperClassName as string]: !!props.wrapperClassName },
        props.className,
      )}
      {...props}
    >
      <span>{children}</span>
      <span className='ml-2 text-[16px]'>{icon}</span>
    </span>
  );
};
