import { FC, SVGProps } from 'react';

import { baseIconProps } from './base-icon-props';

export const ResetIcon: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg {...baseIconProps} width='20' height='20' viewBox='0 0 20 20' fill='currentColor' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M6.00069 16.1663C6.30617 15.9056 6.76523 15.9418 7.02602 16.2473C7.35991 16.6384 7.74132 16.9881 8.1616 17.2924C8.48695 17.5279 8.55975 17.9826 8.32421 18.308C8.08867 18.6333 7.63398 18.7061 7.30863 18.4706C6.798 18.1009 6.33092 17.6733 5.91977 17.1917C5.65897 16.8862 5.6952 16.4271 6.00069 16.1663Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7.99917 5.88547C8.2516 6.19789 8.20296 6.6558 7.89054 6.90823C6.95978 7.66024 6.24375 8.66533 5.83533 9.81779C5.70116 10.1964 5.28549 10.3945 4.9069 10.2604C4.52831 10.1262 4.33017 9.71051 4.46434 9.33192C4.96258 7.92599 5.83685 6.69755 6.97641 5.77683C7.28883 5.5244 7.74674 5.57304 7.99917 5.88547Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M18.8072 5.45703C19.2089 5.45703 19.5345 5.78264 19.5345 6.1843V8.52208C19.5345 8.92374 19.2089 9.24935 18.8072 9.24935H16.4695C16.0678 9.24935 15.7422 8.92374 15.7422 8.52208C15.7422 8.12042 16.0678 7.79481 16.4695 7.79481H18.08V6.1843C18.08 5.78264 18.4056 5.45703 18.8072 5.45703Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11.9999 5.45455C11.3368 5.45455 10.6977 5.55483 10.0943 5.73826C9.70996 5.85508 9.30372 5.63824 9.1869 5.25394C9.07009 4.86965 9.28692 4.46341 9.67122 4.34659C10.4076 4.12275 11.1891 4 11.9999 4C15.0339 4 17.6709 5.68946 19.0262 8.1737C19.2186 8.5263 19.0887 8.96808 18.7361 9.16045C18.3835 9.35283 17.9417 9.22294 17.7494 8.87034C16.6385 6.83418 14.4802 5.45455 11.9999 5.45455Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M13.942 11.3508C14.3011 11.5308 14.4462 11.9679 14.2661 12.3269L12.9166 15.0178C12.7365 15.3768 12.2995 15.5219 11.9405 15.3419C11.5814 15.1618 11.4363 14.7248 11.6164 14.3658L12.9659 11.6748C13.1459 11.3158 13.583 11.1707 13.942 11.3508Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12.0592 8.65936C12.4182 8.83942 12.5633 9.27645 12.3833 9.63549L11.0338 12.3264C10.8537 12.6854 10.4167 12.8305 10.0577 12.6505C9.69861 12.4704 9.55352 12.0334 9.73358 11.6743L11.0831 8.98344C11.2631 8.62439 11.7002 8.4793 12.0592 8.65936Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9.65674 12.0007C9.65674 11.599 9.98235 11.2734 10.384 11.2734H13.6163C14.018 11.2734 14.3436 11.599 14.3436 12.0007C14.3436 12.4024 14.018 12.728 13.6163 12.728H10.384C9.98235 12.728 9.65674 12.4024 9.65674 12.0007Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M19.273 11.2734C19.6746 11.2734 20.0002 11.599 20.0002 12.0007C20.0002 16.4185 16.4189 20.0007 12.0002 20.0007C11.2962 20.0007 10.6293 19.8931 10.0017 19.7301C9.61289 19.6291 9.37959 19.2321 9.48056 18.8433C9.58153 18.4546 9.97854 18.2213 10.3673 18.3222C10.9033 18.4615 11.4453 18.5462 12.0002 18.5462C15.6155 18.5462 18.5457 15.6153 18.5457 12.0007C18.5457 11.599 18.8713 11.2734 19.273 11.2734Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4.72727 11.2734C5.12893 11.2734 5.45455 11.599 5.45455 12.0007C5.45455 12.764 5.59199 13.4949 5.83543 14.1821C5.96955 14.5607 5.77136 14.9764 5.39275 15.1105C5.01414 15.2446 4.59849 15.0464 4.46437 14.6678C4.16963 13.8358 4 12.9409 4 12.0007C4 11.599 4.32561 11.2734 4.72727 11.2734Z'
        fill='currentColor'
      />
    </svg>
  );
};
