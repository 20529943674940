import i18n from 'i18next';
import { put, takeLatest } from 'redux-saga/effects';

import { notify } from '@root/shared/utils/notification';

import { UserSettingsActions, userSettingsSlice } from './user-settings.slice';

function* tgAccountLinked(action: UserSettingsActions.TgAccountLinked) {
  console.log('tgAccountLinked', action.payload);
  yield put(userSettingsSlice.actions.setTgAccounts(action.payload?.id ? [action.payload] : []));

  notify({
    title: i18n.t('common:telegramAccountConnected') as string,
    type: 'success',
  });
}

function* tgAccountUnlinked() {
  yield put(userSettingsSlice.actions.setTgAccounts([]));

  notify({
    title: i18n.t('common:telegramAccountUnlinked') as string,
    type: 'success',
  });
}

export function* userSettingsSaga() {
  yield takeLatest([userSettingsSlice.actions.tgAccountLinked], tgAccountLinked);
  yield takeLatest([userSettingsSlice.actions.tgAccountUnlinked], tgAccountUnlinked);
}
