import { combineReducers } from '@reduxjs/toolkit';

import { userAgentSlice } from '@root/infra/user-agent';
import { accountsSlice } from '@root/modules/accounts/store/accounts.slice';
import { settingsSlice } from '@root/modules/app-settings/store/settings.slice';
import { countriesSlice } from '@root/modules/countries/store/countries.slice';
import { expertsSlice } from '@root/modules/experts/store/experts.slice';
import { marketplaceSlice } from '@root/modules/marketplace/store/marketplace.slice';
import { ordersSlice } from '@root/modules/orders/store/orders.slice';
import { quotesSlice } from '@root/modules/quotes/store/quotes.slice';
import { userSettingsSlice } from '@root/modules/user-settings/store/user-settings.slice';
import { authSlice } from '@root/shared-files/modules/auth/store/auth.slice';
import { maintenanceSlice } from '@root/shared-files/modules/maintenance/store/maintenance.slice';
import { subscriptionsSlice } from '@root/shared-files/modules/subscriptions/store/subscriptions.slice';

export const rootReducer = combineReducers({
  auth: authSlice.reducer,
  userAgent: userAgentSlice.reducer,
  userSettings: userSettingsSlice.reducer,
  subscriptions: subscriptionsSlice.reducer,
  settings: settingsSlice.reducer,
  experts: expertsSlice.reducer,
  accounts: accountsSlice.reducer,
  countries: countriesSlice.reducer,
  marketplace: marketplaceSlice.reducer,
  quotes: quotesSlice.reducer,
  orders: ordersSlice.reducer,
  maintenance: maintenanceSlice.reducer,
});

export type IAppState = ReturnType<typeof rootReducer>;
