import styled from 'styled-components';

import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { GenieGptIcon } from '@root/shared/icons/genie-gpt';
import { InfoTooltip } from '@root/shared/ui/info-tooltip';
import { Text } from '@root/shared/ui/typography';
import { externalRedirect } from '@root/shared/utils/helpers/external-redirect';

const IconWrapper = styled.div`
  background: ${({ theme }) => theme.gradient[7]};
  width: 100%;
  height: 30px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
`;

const BetaTextWrapper = styled.p`
  text-align: center;
  background: ${({ theme }) => theme.grayscale[800]};
  color: ${({ theme }) => theme.system.green};
  font-size: 10px;
  margin: 0;
  height: 14px;
  width: 100%;
  font-weight: 600;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
`;

const Wrapper = styled.div`
  height: 44px;
  width: 44px;
  cursor: pointer;

  &:hover ${BetaTextWrapper} {
    background: ${({ theme }) => theme.gradient[7]};
    color: ${({ theme }) => theme.grayscale[800]};
  }
`;

export const MasterGptLink: FC = () => {
  const { t } = useTranslation('common');

  const redirectToGpt = useCallback(() => {
    externalRedirect(import.meta.env.VITE_CHAT_LINK, { target: '_blank' });
  }, []);

  return (
    <InfoTooltip content={<Text size='sm'>{t('tryMasterGptBeta')}</Text>} positions={['bottom', 'left', 'right']}>
      <Wrapper onClick={redirectToGpt}>
        <IconWrapper className='flex flex-col justify-center items-center'>
          <GenieGptIcon height={24} width={28} />
        </IconWrapper>
        <BetaTextWrapper>2.0</BetaTextWrapper>
      </Wrapper>
    </InfoTooltip>
  );
};
