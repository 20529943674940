import { useField } from 'formik';
import { FC, ReactNode, useCallback, useMemo } from 'react';

import { Field, FieldHint, Label, Select, SelectProps } from '@root/shared/ui/form';

export interface SelectFieldProps extends SelectProps {
  label?: ReactNode;
  hint?: ReactNode;
  name: string;
  hideError?: boolean;
  transparent?: boolean;
  handleChange?: (value: any) => void;
  forceHideValue?: boolean;
  wrapperClassName?: string;
}

export const SelectField: FC<SelectFieldProps> = ({ label, hideError, name, wrapperClassName, handleChange = () => {}, ...props }) => {
  const [inputProps, meta, { setValue, setTouched }] = useField(name);

  const value = useMemo<any>(() => {
    if (!props.isMulti) {
      return props.options?.find((option: any) => option.value === inputProps.value) || null;
    } else {
      return props.options?.filter((option: any) => inputProps.value?.includes(option?.value)) || [];
    }
  }, [inputProps.value, props.isMulti, props.options]);

  const onChange = useCallback(
    (option) => {
      let value;
      if (props.isMulti) {
        value = option?.map((item) => item.value) || [];
        setValue(value);
        handleChange(value);
      } else {
        value = option?.value || '';
        setValue(value);
        handleChange(value);
      }
    },
    [setValue, props.isMulti, handleChange],
  );

  const onBlur = useCallback(() => {
    setTimeout(() => {
      setTouched(true);
    });
  }, [setTouched]);

  return (
    <Field className={wrapperClassName}>
      {label && <Label disabled={props.isDisabled}>{label}</Label>}
      <Select value={value} onChange={onChange} onBlur={onBlur} {...props} />
      {!hideError && <FieldHint variant='danger'>{meta.touched && meta.error}</FieldHint>}
    </Field>
  );
};
