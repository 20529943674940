import { FC, SVGProps } from 'react';

import { baseIconProps } from './base-icon-props';

export const LogoIcon: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg {...baseIconProps} viewBox='0 0 40 40' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M33.2615 23.1261C27.2484 23.1261 22.3564 28.0029 22.3564 34.0021H15.0872C15.0872 29.9249 16.4441 26.1599 18.7336 23.1261H8.40738L6 15.8761C12.0131 15.8761 16.905 10.9993 16.905 5H24.1743C24.1743 9.07719 22.8173 12.8422 20.5322 15.8761H30.8541L33.2615 23.1261Z'
        fill='#24F0B3'
      />
    </svg>
  );
};
