import { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { accountsSlice } from '../store/accounts.slice';
import { useGetAccounts } from './use-get-accounts';

export const useCheckAccounts = () => {
  const { data } = useGetAccounts();
  const dispatch = useDispatch();

  const invalidAccounts = useMemo(() => {
    return data?.filter((item) => !item.serviceAccountId) || [];
  }, [data]);

  useEffect(() => {
    if (invalidAccounts.length) {
      dispatch(accountsSlice.actions.accountEditOpened(invalidAccounts[0].id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invalidAccounts.length, dispatch]);
};
