import { FC, SVGProps } from 'react';

import { baseIconProps } from './base-icon-props';

export const ProfileIcon: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg {...baseIconProps} viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12 16.5C9.44799 16.5 7.02253 17.4854 5.50885 18.2684C4.8375 18.6156 4.38391 19.3233 4.28134 20.1878L4.12566 21.5H19.8743L19.7187 20.1878C19.6161 19.3233 19.1625 18.6156 18.4912 18.2684C16.9775 17.4854 14.552 16.5 12 16.5ZM4.59 16.492C6.22097 15.6483 8.98227 14.5 12 14.5C15.0177 14.5 17.779 15.6483 19.41 16.492C20.7689 17.1948 21.538 18.5469 21.7047 19.9522L21.993 22.3822C22.0267 22.6659 21.9374 22.9505 21.7476 23.1641C21.5578 23.3778 21.2857 23.5 21 23.5H3.00001C2.71427 23.5 2.44218 23.3778 2.2524 23.1641C2.06263 22.9505 1.9733 22.6659 2.00697 22.3822L2.29527 19.9522C2.46201 18.5469 3.23113 17.1948 4.59 16.492Z'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12 3C10.067 3 8.5 4.567 8.5 6.5C8.5 8.433 10.067 10 12 10C13.933 10 15.5 8.433 15.5 6.5C15.5 4.567 13.933 3 12 3ZM6.5 6.5C6.5 3.46243 8.96243 1 12 1C15.0376 1 17.5 3.46243 17.5 6.5C17.5 9.53757 15.0376 12 12 12C8.96243 12 6.5 9.53757 6.5 6.5Z'
      />
    </svg>
  );
};
