import axios, { AxiosError } from 'axios';

import type { IHttpResponse } from '@root/shared/utils/http-service';

import { AuthTokensMapper, AuthUserMapper } from '../mappers';
import { IAuth } from '../types';

export type RefreshTokenServiceResponse = IHttpResponse<200, IAuth> | IHttpResponse<401, string>;

export const refreshTokenService = async (refreshToken: string): Promise<RefreshTokenServiceResponse> => {
  try {
    const response = await axios.post('/auth/refresh-session', { refreshToken }, { baseURL: import.meta.env.VITE_API_HOST });

    return {
      status: 200,
      payload: {
        tokens: AuthTokensMapper.toDomain(response.data),
        userSubscriptionInfo: AuthUserMapper.collectSubscriptionInfo(response.data),
        user: AuthUserMapper.toDomain(response.data.user),
      },
    };
  } catch (e) {
    const error = e as AxiosError;
    console.error(e);

    if (error.isAxiosError && error.response) {
      return {
        status: 401,
        payload: error.response.data.message,
      };
    }

    return {
      status: 401,
      payload: 'Bad request',
    };
  }
};
