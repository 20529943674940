import { AxiosError } from 'axios';

import { fetcher } from '@root/infra/fetcher';
import { handleAxiosError } from '@root/shared/utils/helpers/handle-axios-error';
import type { IHttpResponse } from '@root/shared/utils/http-service';

export type RunExpertServiceResponse = IHttpResponse<200, string> | IHttpResponse<400, string>;
export type RunExpertServiceValues = { id: string; symbols: string[]; isAcknowledgementAccepted?: boolean };

export const runExpertService = async ({ id, symbols, isAcknowledgementAccepted }: RunExpertServiceValues): Promise<RunExpertServiceResponse> => {
  try {
    await fetcher.post(`/experts/${id}/start`, { symbols, isAcknowledgementAccepted }, { baseURL: import.meta.env.VITE_FX_CORE_HOST });

    return {
      status: 200,
      payload: id,
    };
  } catch (e) {
    return handleAxiosError(e as AxiosError);
  }
};
