import { useSelector } from 'react-redux';

import { authSelector } from '../store';

export const useGhostLogin = () => {
  const ghostToken = useSelector(authSelector.ghostToken);

  return {
    isGhostLogin: !!ghostToken,
    hideActions: !!ghostToken,
  };
};
