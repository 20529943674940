import { RefObject, useEffect } from 'react';

import { isNode } from '../utils/node/is-node';
import { useEvent } from './use-event';

type NodeRefObject = RefObject<Node | undefined | null>;

type UseOutsideClick = {
  (ref: NodeRefObject, onOutsideClick: (event: MouseEvent) => void): void;
  (refs: NodeRefObject[], onOutsideClick: (event: MouseEvent) => void): void;
};

export const useOutsideClick: UseOutsideClick = (arg1: NodeRefObject | NodeRefObject[], onOutsideClick: (event: MouseEvent) => void) => {
  const handleOutsideClick = useEvent((event: MouseEvent) => {
    const { target } = event;

    if (!isNode(target)) {
      return;
    }

    const refs = Array.isArray(arg1) ? arg1 : [arg1];

    const clickIsInside = refs.some(({ current }) => isNode(current) && (current === target || current.contains(target)));

    if (clickIsInside) {
      return;
    }

    onOutsideClick(event);
  });

  useEffect(() => {
    document.addEventListener('click', handleOutsideClick);

    return () => document.removeEventListener('click', handleOutsideClick);
  }, [handleOutsideClick]);
};
