import { CaseReducer, PayloadAction, createSlice } from '@reduxjs/toolkit';

import { TelegramAccount } from '../types/telegram';

export type IUserSettingsState = {
  feedbackOpened: boolean;
  menuOpened: boolean;
  tgAccounts: TelegramAccount[];
};

export namespace UserSettingsActions {
  export type SetFeedbackModal = PayloadAction<boolean>;
  export type SetMenuOpened = PayloadAction<boolean>;
  export type TgAccountLinked = PayloadAction<TelegramAccount>;
  export type TgAccountUnlinked = PayloadAction<void>;
  export type SetTgAccounts = PayloadAction<TelegramAccount[]>;
}

export type ExchangesCaseReducer = {
  setFeedbackModal: CaseReducer<IUserSettingsState, UserSettingsActions.SetFeedbackModal>;
  setMenuOpened: CaseReducer<IUserSettingsState, UserSettingsActions.SetMenuOpened>;
  setTgAccounts: CaseReducer<IUserSettingsState, UserSettingsActions.SetTgAccounts>;
  tgAccountLinked: CaseReducer<IUserSettingsState, UserSettingsActions.TgAccountLinked>;
  tgAccountUnlinked: CaseReducer<IUserSettingsState, UserSettingsActions.TgAccountLinked>;
};

const initialState: IUserSettingsState = {
  feedbackOpened: false,
  menuOpened: false,
  tgAccounts: [],
};

export const userSettingsSlice = createSlice<IUserSettingsState, ExchangesCaseReducer, 'userSettings'>({
  name: 'userSettings',
  initialState,
  reducers: {
    setFeedbackModal: (state, action) => {
      state.feedbackOpened = action.payload;
    },
    setMenuOpened: (state, action) => {
      state.menuOpened = action.payload;
    },
    tgAccountLinked: (state, action) => {},
    tgAccountUnlinked: (state, action) => {},
    setTgAccounts: (state, action) => {
      state.tgAccounts = action.payload;
    },
  },
});
