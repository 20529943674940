import { SignInDto } from '@root/shared-files/modules/auth/dtos';

import { AuthTokensMapper } from './auth-tokens.mapper';
import { AuthUserMapper } from './auth-user.mapper';

export class SignInDtoMapper {
  public static toDomain(data) {
    return {
      isTwoFactorEnabled: data.isTwoFactorEnabled,
      twoFactorResponse: data.twoFactorResponse,
      session: data.session
        ? {
            user: AuthUserMapper.toDomain(data.session.user),
            tokens: AuthTokensMapper.toDomain(data.session),
            userSubscriptionInfo: AuthUserMapper.collectSubscriptionInfo(data.session),
          }
        : undefined,
    };
  }

  public static toPersistence(dto: SignInDto) {
    return {
      email: dto.email,
      password: dto.password,
      captchaToken: dto.captchaToken,
    };
  }
}
