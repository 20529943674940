import styled from 'styled-components';

import clsx from 'clsx';
import { FC, PropsWithChildren } from 'react';

import { useGhostLogin } from '@root/shared-files/modules/auth/hooks/use-ghost-login';

import { useXl } from '../hooks/media';
import { HeaderElements as Header } from './header.elements';
import { SidebarElements as Sidebar } from './sidebar.elements';

type Props = PropsWithChildren<{
  isGhostLogin: boolean;
  extraHeaderHeight?: number;
  isDesktop: boolean;
  menuOpened: boolean;
  isGuestView?: boolean;
}>;

const SafeAreaWrapper = styled.div<{ extraHeaderHeight?: number }>`
  nav {
    top: ${({ extraHeaderHeight }) => `${extraHeaderHeight || 0}px`};
  }
`;

const SafeArea: FC<Props> = ({ children, isGhostLogin, extraHeaderHeight, isDesktop, menuOpened, isGuestView }) => {
  const xl = useXl();

  return (
    <SafeAreaWrapper
      style={{
        paddingTop: `${extraHeaderHeight ? `${74 + extraHeaderHeight || 0}px` : '74px'}`,
      }}
      className={clsx('w-full bg-gray-900', {
        'mt-[60px]': isGhostLogin,
        'pl-0': isGuestView,
        'pl-[200px] pr-0 rtl:pl-0 rtl:pr-[200px]': isDesktop && xl && menuOpened,
        'pl-[64px] pr-0 rtl:pl-0 rtl:pr-[64px]': isDesktop && xl && !menuOpened,
      })}
      extraHeaderHeight={extraHeaderHeight}
    >
      {children}
    </SafeAreaWrapper>
  );
};

const Content: FC<PropsWithChildren> = ({ children }) => {
  const { isGhostLogin } = useGhostLogin();

  return <main className={clsx('p-4 sm:p-5', { 'mt-[60px]': isGhostLogin })}>{children}</main>;
};

export const LayoutElements = { SafeArea, Content, Sidebar, Header };
