import { AxiosError } from 'axios';

import { fetcher } from '@root/infra/fetcher';
import type { IHttpResponse } from '@root/shared/utils/http-service';

import { SignInDto } from '../dtos';
import { SignInDtoMapper } from '../mappers';
import { SignInResponse } from '../types/sign-in';

export type SignInServiceResponse = IHttpResponse<200, SignInResponse> | IHttpResponse<400, string>;

export const signInService = async (payload: SignInDto): Promise<SignInServiceResponse> => {
  try {
    const response = await fetcher.post('/auth/sign-in', SignInDtoMapper.toPersistence(payload));

    return {
      status: 200,
      payload: SignInDtoMapper.toDomain(response.data),
    };
  } catch (e) {
    const error = e as AxiosError;
    console.error(e);

    if (error.isAxiosError && error.response) {
      const { message } = error.response?.data;
      if (typeof message === 'object' && message?.length) {
        return {
          status: 400,
          payload: error.response.data.message?.[0]?.errors?.[0],
        };
      } else {
        return {
          status: 400,
          payload: message,
        };
      }
    }

    return {
      status: 400,
      payload: 'Bad request',
    };
  }
};
