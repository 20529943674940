import { createSelector } from '@reduxjs/toolkit';

import { IAppState } from '@root/infra/store';

import { IUserSettingsState } from './user-settings.slice';

export const getUserSettings = (state: IAppState) => state.userSettings;
export const getFeedbackModalOpened = (state: IUserSettingsState) => state.feedbackOpened;
export const getMenuOpened = (state: IUserSettingsState) => state.menuOpened;
export const getTgAccounts = (state: IUserSettingsState) => state.tgAccounts;

export const userSettingsSelector = {
  feedbackOpened: createSelector([getUserSettings], getFeedbackModalOpened),
  menuOpened: createSelector([getUserSettings], getMenuOpened),
  tgAccounts: createSelector([getUserSettings], getTgAccounts),
};
