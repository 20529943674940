import { AxiosError } from 'axios';

import { fetcher } from '@root/infra/fetcher';
import type { IHttpResponse } from '@root/shared/utils/http-service';

export type SendCodeSignInResponse = IHttpResponse<200, { success: boolean; errorCode: string }> | IHttpResponse<400, string>;

export const sendCodeSignIn = async (): Promise<SendCodeSignInResponse> => {
  try {
    const response = await fetcher.post('/auth/2fa-code/email/send-code');

    return {
      status: 200,
      payload: response.data,
    };
  } catch (e) {
    const error = e as AxiosError;
    console.error(e);

    if (error.isAxiosError && error.response) {
      const message = error.response?.data?.message;
      if (typeof message === 'object' && message?.length) {
        return {
          status: 400,
          payload: error.response.data.message?.[0]?.errors?.[0],
        };
      } else {
        return {
          status: 400,
          payload: message,
        };
      }
    }

    return {
      status: 400,
      payload: 'Bad request',
    };
  }
};
