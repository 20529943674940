import get from 'lodash/get';
import omit from 'lodash/omit';

import clsx from 'clsx';
import { useField, useFormikContext } from 'formik';
import { ReactNode, forwardRef } from 'react';

import { Field, FieldHint, Input, InputProps, Label } from '@root/shared/ui/form';

export interface TextFieldProps extends InputProps {
  label?: ReactNode;
  name: string;
  hideError?: boolean;
  showUnTouchedFieldError?: boolean;
  wrapperClassName?: string;
}

const normalizeNumber = (val: string, prevVal: string): string => {
  const value = val
    .toString()
    .replace(/[^0-9.,]/g, '')
    .replace(/,/g, '.');
  const prevValue = prevVal.toString();

  // ! Removing last dot if already exist
  if (prevValue.includes('.') && value.endsWith('.')) {
    return value.slice(0, -1);
  }

  // append 0 before dot
  if (value.startsWith('.')) {
    return value.replace('.', '0.');
  }

  // !Adding dot after 0
  if (value.length > 1 && value.startsWith('0') && !['.'].includes(value[1])) {
    return value.replace('0', '0.');
  }

  return value;
};

export const TextField = forwardRef<HTMLInputElement, TextFieldProps>(function TextField(
  { label, variant, hideError, type = 'text', showUnTouchedFieldError = false, wrapperClassName, ...props },
  ref,
) {
  const [inputProps, meta] = useField(props.name);
  const { status, setStatus } = useFormikContext();
  const apiError = get(status?.apiErrors, props.name);

  const errorText = meta.error || apiError;
  const showError = showUnTouchedFieldError ? typeof errorText === 'string' : meta.touched && typeof errorText === 'string';

  const changeFunction = props.onChange || inputProps.onChange;

  const clearError = () => {
    if (apiError) {
      const errors = omit(status?.apiErrors, [props.name]);
      setStatus({ apiErrors: errors });
    }
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    clearError();
    if (type === 'number') {
      return changeFunction({
        target: { name: e.target.name, value: normalizeNumber(e.target.value, inputProps.value) },
      } as React.ChangeEvent<HTMLInputElement>);
    }
    return changeFunction(e);
  };

  return (
    <Field className={clsx({ 'pointer-events-none': props.disabled, 'opacity-30': props.disabled }, wrapperClassName)}>
      {label && <Label>{label}</Label>}
      <Input type={type !== 'number' ? type : 'text'} variant={showError ? 'danger' : variant} {...inputProps} {...props} onChange={onChange} ref={ref} />
      {!hideError && <FieldHint variant='danger'>{showError && (meta.error || apiError)}</FieldHint>}
    </Field>
  );
});
