import styled from 'styled-components';

import clsx from 'clsx';
import { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { userSettingsSelector } from '@root/modules/user-settings/store/user-settings.selector';
import { CryptoIcon } from '@root/shared/icons/crypto-icon';
import { FxIcon } from '@root/shared/icons/fx-icon';
import { GenieGptIcon } from '@root/shared/icons/genie-gpt';
import { externalRedirect } from '@root/shared/utils/helpers/external-redirect';

import { useXl } from '../hooks/media';
import { LayoutElements } from './layout.elements';

const MainLinkWrapper = styled.div<{ isOpen: boolean }>`
  width: ${({ isOpen }) => (isOpen ? '200px' : '64px')};
`;

const { HeaderLink, NestedHeaderLink } = LayoutElements.Header;

const MainLink: FC<{ isOpen: boolean }> = ({ isOpen }) => {
  const { t } = useTranslation('common');
  const menuOpened = useSelector(userSettingsSelector.menuOpened);
  const xl = useXl();

  const itemViewIsWide = xl && menuOpened;

  return (
    <MainLinkWrapper className='relative flex items-center' isOpen={itemViewIsWide}>
      <HeaderLink
        icon={<FxIcon className='text-[19px]' />}
        isOpen={isOpen}
        title={itemViewIsWide ? t('sideBar.fx') : ''}
        className={clsx('w-full flex !justify-center', { '!flex-row !gap-x-4 px-4': itemViewIsWide, 'gap-y-2': !itemViewIsWide })}
      />
    </MainLinkWrapper>
  );
};

export const HeaderLinksDropdown = () => {
  const { t } = useTranslation('common');
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const menuOpened = useSelector(userSettingsSelector.menuOpened);
  const xl = useXl();

  const itemViewIsWide = xl && menuOpened;

  const redirectToCryptoSite = useCallback(() => {
    externalRedirect(import.meta.env.VITE_CRYPTO_SITE);
  }, []);

  const redirectToGpt = useCallback(() => {
    externalRedirect(import.meta.env.VITE_CHAT_LINK);
  }, []);

  return (
    <div className='relative bg-grayscale-800' onMouseEnter={() => setIsOpen(true)} onMouseLeave={() => setIsOpen(false)}>
      <MainLink isOpen={isOpen} />

      {isOpen && (
        <div className='flex flex-col gap-0 absolute top-0 left-0 bg-grayscale-800 rounded z-101'>
          <MainLink isOpen={isOpen} />

          <div onClick={redirectToCryptoSite}>
            <NestedHeaderLink isActive={false} title={itemViewIsWide ? t('sideBar.crypto') : ''} icon={<CryptoIcon />} isOpen={itemViewIsWide} />
          </div>
          <div onClick={redirectToGpt}>
            <NestedHeaderLink isActive={false} title={itemViewIsWide ? t('sideBar.masterGpt') : ''} icon={<GenieGptIcon width={20} height={20} />} isOpen={itemViewIsWide} />
          </div>
          {/* <InfoTooltip content={<div>{t('releaseSoon')}</div>} positions={['right']}>
            <NestedHeaderLink disabled title={menuOpened ? t('sideBar.fx') as string : ''} icon={<FXIcon />} isOpen={isOpen} />
          </InfoTooltip> */}
          {/* <InfoTooltip content={<div>{t('releaseSoon')}</div>} positions={['right']}>
            <NestedHeaderLink disabled title={menuOpened ? (t('sideBar.stock') as string) : ''} icon={<StockIcon />} isOpen={menuOpened} />
          </InfoTooltip> */}
        </div>
      )}
    </div>
  );
};
