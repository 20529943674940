import { FC, SVGProps } from 'react';

import { baseIconProps } from './base-icon-props';

export const VerifiedBadgeIcon: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg {...baseIconProps} viewBox='0 0 17 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g clipPath='url(#clip0_14915_42053)'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M6.45287 1.43919C7.51342 0.15048 9.48664 0.15048 10.5472 1.43919C10.8261 1.77807 11.2554 1.95589 11.6922 1.91347C13.3534 1.75214 14.7487 3.14741 14.5873 4.80859C14.5449 5.24542 14.7227 5.67472 15.0616 5.95361C16.3503 7.01416 16.3503 8.98737 15.0616 10.0479C14.7227 10.3268 14.5449 10.7561 14.5873 11.1929C14.7487 12.8541 13.3534 14.2494 11.6922 14.0881C11.2554 14.0456 10.8261 14.2235 10.5472 14.5623C9.48664 15.851 7.51342 15.851 6.45287 14.5623C6.17398 14.2235 5.74469 14.0456 5.30785 14.0881C3.64668 14.2494 2.25141 12.8541 2.41274 11.1929C2.45516 10.7561 2.27734 10.3268 1.93845 10.0479C0.649748 8.98737 0.649748 7.01416 1.93845 5.95361C2.27734 5.67472 2.45516 5.24542 2.41274 4.80858C2.25141 3.14741 3.64668 1.75214 5.30785 1.91347C5.74469 1.95589 6.17398 1.77807 6.45287 1.43919ZM9.51766 2.28644C8.99047 1.64584 8.0096 1.64584 7.4824 2.28644C6.92136 2.96819 6.05775 3.3259 5.17897 3.24056C4.35321 3.16036 3.65963 3.85394 3.73983 4.6797C3.82517 5.55848 3.46745 6.42209 2.78571 6.98314C2.14511 7.51033 2.14511 8.4912 2.78571 9.01839C3.46745 9.57944 3.82517 10.443 3.73983 11.3218C3.65963 12.1476 4.35321 12.8412 5.17897 12.761C6.05775 12.6756 6.92136 13.0333 7.4824 13.7151C8.0096 14.3557 8.99047 14.3557 9.51766 13.7151C10.0787 13.0333 10.9423 12.6756 11.8211 12.761C12.6469 12.8412 13.3404 12.1476 13.2602 11.3218C13.1749 10.443 13.5326 9.57944 14.2143 9.01839C14.855 8.4912 14.855 7.51033 14.2143 6.98314C13.5326 6.42209 13.1749 5.55848 13.2602 4.6797C13.3404 3.85394 12.6469 3.16036 11.8211 3.24056C10.9423 3.3259 10.0787 2.96819 9.51766 2.28644Z'
          fill='currentColor'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M6.02859 7.49344C6.28894 7.23309 6.71105 7.23309 6.9714 7.49344L7.91421 8.43625L10.2712 6.07923C10.5316 5.81888 10.9537 5.81888 11.214 6.07923C11.4744 6.33958 11.4744 6.76169 11.214 7.02203L7.91421 10.3219L6.02859 8.43625C5.76824 8.1759 5.76824 7.75379 6.02859 7.49344Z'
          fill='currentColor'
        />
      </g>
      <defs>
        <clipPath id='clip0_14915_42053'>
          <rect width='16' height='16' fill='white' transform='translate(0.5)' />
        </clipPath>
      </defs>
    </svg>
  );
};
