import { ReactNode } from 'react';
import { Provider } from 'react-redux';

import { injectStore } from '@root/infra/fetcher/fetcher.interceptors';
import { store } from '@root/infra/store';

export const StoreProvider = ({ children }: { children: ReactNode }) => {
  injectStore(store);
  return <Provider store={store}>{children}</Provider>;
};
