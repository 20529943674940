import styled from 'styled-components';

import clsx from 'clsx';
import { JSXElementConstructor, MouseEventHandler, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { FeedbackContent } from '@root/infra/layout/containers/feedback-tooltip';
import { useGetAccounts } from '@root/modules/accounts/hooks/use-get-accounts';
import { useGetExperts } from '@root/modules/experts/hooks/use-get-experts';
import { userSettingsSelector } from '@root/modules/user-settings/store/user-settings.selector';
import { userSettingsSlice } from '@root/modules/user-settings/store/user-settings.slice';
import { convertPermissionLimitToHumanReadable } from '@root/shared-files/modules/auth/helpers';
import { useGhostLogin } from '@root/shared-files/modules/auth/hooks/use-ghost-login';
import { authSelector } from '@root/shared-files/modules/auth/store';
import { PageLinks } from '@root/shared/constants/pageLinks';
import { helpLinks } from '@root/shared/constants/social-links';
import { useOutsideClick } from '@root/shared/hooks/use-outside-click';
import { usePrevious } from '@root/shared/hooks/use-previous';
import { ArrowIcon } from '@root/shared/icons/arrow-icon';
import { BoldIcon } from '@root/shared/icons/bold-icon';
import { ChevronIcon } from '@root/shared/icons/chevron-icon';
import { CursorIcon } from '@root/shared/icons/cursor-icon';
import { DashboardIcon } from '@root/shared/icons/dashboard-icon';
import { DiscordWhiteIcon } from '@root/shared/icons/discord-icon';
import { ExpertsIcon } from '@root/shared/icons/experts-icon';
import { FeedbackIcon } from '@root/shared/icons/feedback-icon';
import { HelixIcon } from '@root/shared/icons/helix-icon';
import { KnowledgeBaseOutlinedIcon } from '@root/shared/icons/knowledge-base-outlined-icon';
import { MagicTerminalIcon } from '@root/shared/icons/magic-terminal-icon';
import { ProvidersIcon } from '@root/shared/icons/providers-icon';
import { RoadmapIcon } from '@root/shared/icons/roadmap-icon';
import { ScannersIcon } from '@root/shared/icons/scanners-icon';
import { SignalsIcon } from '@root/shared/icons/signals-icon';
import { SubscriptionsIcon } from '@root/shared/icons/subscriptions-icon';
import { TelegramWhiteIcon } from '@root/shared/icons/telegram-white-icon';
import { YoutubeWhiteIcon } from '@root/shared/icons/youtube-white-icon';
import { ActiveLink } from '@root/shared/link/active-link';
import { Button } from '@root/shared/ui/button';
import { Text } from '@root/shared/ui/typography';
import { invertBoolean } from '@root/shared/utils/boolean/invert-boolean';

import { LayoutElements } from '../components';
import { useXl } from '../hooks/media';

const { HelpItem, Help, Wrapper, Item } = LayoutElements.Sidebar;

const SidebarWrapper = styled(Wrapper)`
  font-size: 14px !important;
  padding-bottom: 72px;
`;

export const staticMenu = {
  top: [
    {
      title: 'dashboard',
      Icon: DashboardIcon,
      url: '/',
    },

    {
      title: 'accounts',
      Icon: BoldIcon,
      url: '/accounts',
    },
    // {
    //   title: 'templates',
    //   iconId: 'templates',
    //   url: '/templates',
    // },
    {
      title: 'experts',
      Icon: ExpertsIcon,
      url: '/assists',
    },
    {
      title: 'magicTerminal',
      Icon: MagicTerminalIcon,
      url: '/magic-terminal',
      newLabel: 'beta',
    },
    // {
    //   title: 'remap',
    //   iconId: 'transfer',
    //   url: '/symbol-remap',
    // },
  ],
  center: [
    {
      title: 'providers',
      Icon: ProvidersIcon,
      url: '/marketplace',
    },
    {
      title: 'presets',
      Icon: SignalsIcon,
      url: '/presets',
    },
  ],
  bottom: [
    {
      title: 'subscriptions',
      Icon: SubscriptionsIcon,
      url: '/subscriptions',
    },
    // {
    //   title: 'referral',
    //   Icon: ReferralIcon,
    //   url: '/referral',
    // },
  ],
  scanners: {
    info: {
      title: 'scanners',
      Icon: ScannersIcon,
    },
    items: [
      {
        title: 'heliX',
        Icon: HelixIcon,
        url: '/scanners/helix',
        // url: 'http://hpa.sagemaster.io/'
      },
    ],
  },
  help: [
    {
      titleTranslationId: 'help.knowledgeBase',
      Icon: KnowledgeBaseOutlinedIcon,
      link: helpLinks.knowbase,
    },
    {
      titleTranslationId: 'help.report',
      Icon: CursorIcon,
      link: helpLinks.submitFeature,
    },
    {
      titleTranslationId: 'help.roadmap',
      Icon: RoadmapIcon,
      link: helpLinks.roadmap,
    },
    {
      titleTranslationId: 'help.telegram',
      Icon: TelegramWhiteIcon,
      link: helpLinks.telegram,
    },
    {
      titleTranslationId: 'help.youtube',
      Icon: YoutubeWhiteIcon,
      link: helpLinks.youtube,
    },
    {
      titleTranslationId: 'help.discord',
      Icon: DiscordWhiteIcon,
      link: helpLinks.discord,
    },
    // {
    //   titleTranslationId: 'help.chat',
    //   Icon: ChatIcon,
    //   link: helpLinks.chat,
    // },
  ],
};

type GroupKeys = 'runningAssists' | 'connectedBrokerAccounts';

type LimitItemConfig = {
  key: GroupKeys;
  Icon: JSXElementConstructor<{ width?: number; height?: number; className?: string }>;
  title: string;
  itemTitle: string;
  link: PageLinks;
  value: number | undefined;
  limit: number | undefined;
};

export const Sidebar = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('common', { keyPrefix: 'sideBar' });
  const menuOpened = useSelector(userSettingsSelector.menuOpened);
  const [groupIsOpenedByKeyMap, setGroupOpenedByKeyMap] = useState<Record<GroupKeys, boolean>>({
    runningAssists: true,
    connectedBrokerAccounts: true,
  });
  const [isMarketplaceOpen, setMarketplaceOpen] = useState<boolean>(true);
  const [isHelpOpen, setHelpOpen] = useState<boolean>(false);
  const [isScannersOpen, setIsScannersOpen] = useState<boolean>(true);
  const sideBarRef = useRef<HTMLDivElement>(null);
  const helpContainerRef = useRef<HTMLButtonElement>(null);

  const { isGhostLogin } = useGhostLogin();
  const xl = useXl();
  const previousXl = usePrevious(xl);

  useEffect(() => {
    if (previousXl === xl) {
      return;
    }

    if (xl) {
      if (isHelpOpen) {
        setHelpOpen(false);
      }
    } else {
      if (!isHelpOpen) {
        setHelpOpen(true);
      }
    }
  }, [previousXl, xl, isHelpOpen]);

  useOutsideClick(helpContainerRef, () => {
    if (!xl) {
      return;
    }

    setHelpOpen(false);
  });

  useOutsideClick(sideBarRef, () => {
    if (xl) {
      return;
    }

    dispatch(userSettingsSlice.actions.setMenuOpened(false));
  });

  const handleMenuClick = useCallback(() => {
    dispatch(userSettingsSlice.actions.setMenuOpened(!menuOpened));
  }, [dispatch, menuOpened]);

  const { data } = useGetExperts();
  const { data: accounts } = useGetAccounts();
  const maxEACount = useSelector(authSelector.maxEACount);
  const activeBrokerCount = useSelector(authSelector.activeBrokerCount);
  const runningExperts = data?.filter((expert) => expert.isEnabled);
  const activeAccounts = accounts?.filter((account) => account.isSignedIn);

  const limitItems: LimitItemConfig[] = [
    {
      key: 'runningAssists',
      Icon: ExpertsIcon,
      title: t('limits.runningAssists.title'),
      itemTitle: t('limits.runningAssists.itemTitle'),
      link: PageLinks.experts,
      value: runningExperts?.length,
      limit: maxEACount,
    },
    {
      key: 'connectedBrokerAccounts',
      Icon: BoldIcon,
      title: `${t('limits.connectedBrokerAccounts.title')} (${convertPermissionLimitToHumanReadable(activeBrokerCount)})`,
      itemTitle: t('limits.connectedBrokerAccounts.itemTitle'),
      link: PageLinks.accounts,
      value: activeAccounts?.length,
      limit: activeBrokerCount,
    },
  ];

  const createGroupToggleButtonClickHandler =
    (key: string): MouseEventHandler<HTMLDivElement> =>
    () => {
      setGroupOpenedByKeyMap((prev) => ({ ...prev, [key]: !prev[key] }));
    };

  return (
    <SidebarWrapper className={clsx(!xl && '!w-[290px] sm:!w-[370px]')}>
      <div className='pt-5 xl:pt-0' ref={sideBarRef}>
        {!xl &&
          limitItems.map(({ key, title, itemTitle, Icon, link, value, limit }) => {
            const groupIsOpened = groupIsOpenedByKeyMap[key];

            return (
              <div key={key} className='mb-2'>
                <div className='flex justify-between items-center mx-5 py-2.5 xl:py-4 cursor-pointer' onClick={createGroupToggleButtonClickHandler(key)}>
                  <Text size='sm' className='uppercase text-grayscale-500 font-bold'>
                    {title}
                  </Text>
                  <ChevronIcon className={clsx('ml-8 xl:ml-4 text-gray-400', { 'rotate-180': !groupIsOpened })} />
                </div>
                {groupIsOpened && (
                  <div className='px-5'>
                    <ActiveLink href={link}>
                      <div className='flex items-center px-2 py-2.5 bg-gray-900 rounded'>
                        <Icon width={20} height={20} className='mr-2.5' />
                        <Text className='text-sm'>{itemTitle}</Text>
                        <Text className='ml-auto'>
                          {value} <span className='text-gray-600'>/ {convertPermissionLimitToHumanReadable(limit)}</span>
                        </Text>
                      </div>
                    </ActiveLink>
                  </div>
                )}
              </div>
            );
          })}

        <div>
          <div className='-my-1 xl:my-0'>
            {staticMenu.top.map((item, index) => (
              <ActiveLink key={index} exact={index === 0} href={item.url} className='block w-auto my-1 xl:my-0'>
                <Item
                  title={xl ? t(item.title) : <span className='text-sm'>{t(item.title)}</span>}
                  icon={<item.Icon width={20} height={20} />}
                  className={clsx({ 'pl-5 xl:pl-4': menuOpened, 'items-center': !menuOpened })}
                  menuOpened={menuOpened}
                  newLabel={item.newLabel ? t(item.newLabel) : undefined}
                />
              </ActiveLink>
            ))}
          </div>
        </div>
        <div className='border-t-2 border-black py-0.5'>
          <div className='w-full'>
            {menuOpened && (
              <div className='flex justify-between items-center mx-5 py-2.5 xl:py-4 cursor-pointer' onClick={() => setMarketplaceOpen(invertBoolean)}>
                <Text size='sm' className='uppercase text-grayscale-500 font-bold'>
                  {t('marketplace')}
                </Text>
                <ChevronIcon className={clsx('ml-8 xl:ml-4 text-gray-400', { 'rotate-180': !isMarketplaceOpen })} />
              </div>
            )}
            {((menuOpened && isMarketplaceOpen) || !menuOpened) && (
              <div className='-my-1 xl:my-0'>
                {staticMenu.center.map((item, index) => (
                  <ActiveLink key={index} href={item.url} className='block my-1 xl:my-0'>
                    <Item
                      title={xl ? t(item.title) : <span className='text-sm'>{t(item.title)}</span>}
                      icon={<item.Icon width={20} height={20} />}
                      className={clsx({ 'pl-5 xl:pl-4': menuOpened, 'items-center': !menuOpened })}
                      menuOpened={menuOpened}
                    />
                  </ActiveLink>
                ))}
              </div>
            )}
          </div>
        </div>

        <div className='border-t-2 border-b-2 border-black py-0.5'>
          {menuOpened && (
            <div className='flex justify-between items-center mx-5 py-2.5 xl:py-4 cursor-pointer' onClick={() => setIsScannersOpen(invertBoolean)}>
              <Text size='sm' className='uppercase text-grayscale-500 font-bold'>
                {t(staticMenu.scanners.info.title)}
              </Text>
              <ChevronIcon className={clsx('ml-8 xl:ml-4 text-gray-400', { 'rotate-180': !isScannersOpen })} />
            </div>
          )}
          {((menuOpened && isScannersOpen) || !menuOpened) && (
            <div className='-my-1 xl:my-0'>
              {staticMenu.scanners.items.map((item, index) => (
                <ActiveLink key={index} href={item.url} className='block my-1 xl:my-0'>
                  <Item
                    title={xl ? t(item.title) : <span className='text-sm'>{t(item.title)}</span>}
                    icon={<item.Icon width={20} height={20} />}
                    className={clsx({ 'pl-5 xl:pl-4': menuOpened, 'items-center': !menuOpened })}
                    menuOpened={menuOpened}
                    disabled={isGhostLogin}
                  />
                </ActiveLink>
              ))}
            </div>
          )}
        </div>

        <div>
          <div className='-my-1 xl:my-0'>
            {staticMenu.bottom.map((item, index) => (
              <ActiveLink key={index} exact href={item.url} className={`block my-1 xl:my-0 ${item.url === '/referral' ? 'pointer-events-none' : ''}`}>
                <Item
                  title={<span className='text-sm'>{t(item.title)}</span>}
                  icon={<item.Icon width={20} height={20} />}
                  className={clsx({ 'pl-5 xl:pl-4': menuOpened, 'items-center': !menuOpened })}
                  menuOpened={menuOpened}
                  disabled={item.url === '/referral'}
                />
              </ActiveLink>
            ))}
          </div>
        </div>

        {xl ? (
          <div className='justify-center px-2 py-2'>
            <Help
              onClick={() => setHelpOpen(invertBoolean)}
              className={clsx('flex items-center w-full !py-0', {
                'flex-row-reverse justify-center gap-x-4': menuOpened,
                'flex-col': !menuOpened,
              })}
              ref={helpContainerRef}
            >
              <HelpItem className='py-0'>
                <div className='relative'>
                  <div className='flex justify-center items-center gap-x-2 hover:text-primary-400 cursor-pointer'>
                    {menuOpened ? <Text size='sm'>{t('help.menuText')}</Text> : null}
                    <FeedbackIcon width={20} height={20} />
                  </div>
                  {isHelpOpen && <FeedbackContent />}
                </div>
              </HelpItem>
            </Help>
          </div>
        ) : (
          <div>
            <div className='flex justify-between items-center mx-5 py-2.5 xl:py-4 cursor-pointer' onClick={() => setHelpOpen(invertBoolean)}>
              <Text size='sm' className='uppercase text-grayscale-500 font-bold'>
                {t('help.shortTitle')}
              </Text>
              <ChevronIcon className={clsx('ml-8 xl:ml-4 text-gray-400', { 'rotate-180': !isHelpOpen })} />
            </div>

            <div className='-my-1 xl:my-0'>
              {isHelpOpen &&
                staticMenu.help.map(({ titleTranslationId, Icon, link }, index) => (
                  <ActiveLink key={index} href={link} className='block my-1 xl:my-0'>
                    <Item
                      title={xl ? t(titleTranslationId) : <span className='text-sm'>{t(titleTranslationId)}</span>}
                      icon={<Icon width={20} height={20} />}
                      className={clsx({ 'pl-5 xl:pl-4': menuOpened, 'items-center': !menuOpened })}
                      menuOpened={menuOpened}
                      disabled={isGhostLogin}
                    />
                  </ActiveLink>
                ))}
            </div>
          </div>
        )}
      </div>
      {xl && (
        <div
          className={clsx('py-4 border-t-2 border-black flex flex-col gap-2 fixed bottom-0 left-0 rtl:right-0 mt-[100%] z-100 bg-gray-800', menuOpened ? 'w-[200px]' : 'w-[64px]', {
            'left-[3px] bottom-[4px]': isGhostLogin,
          })}
        >
          <button
            className={clsx('flex rtl:flex-row-reverse justify-start items-center cursor-pointer', {
              'gap-x-3 justify-start pl-5': menuOpened,
              'justify-center': !menuOpened,
            })}
            onClick={handleMenuClick}
          >
            <Button className='!w-8 !h-8 !px-0 m-0 flex justify-center items-center rounded text-gray-100 hover:text-primary-400 '>
              <ArrowIcon
                className={clsx('transform transition duration-200', {
                  '-rotate-90 rtl:rotate-90': menuOpened,
                  'rotate-90 rtl:-rotate-90': !menuOpened,
                })}
                width={24}
                height={16}
              />
            </Button>
            {menuOpened && <Text className='!text-[14px]'>{t('hideMenu')}</Text>}
          </button>
        </div>
      )}
    </SidebarWrapper>
  );
};
