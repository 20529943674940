export enum AccountStatus {
  PendingConnect = 'PENDING_CONNECT',
  Disconnected = 'DISCONNECTED',
  Connected = 'CONNECTED',
}

export enum SymbolType {
  CURRENCY = 'CURRENCY',
  INDEX = 'INDEX',
  SYNTHETIC = 'SYNTHETIC',
}
