import { useCallback } from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';

import { IAccount } from '@root/modules/accounts';
import { AccountStatus } from '@root/modules/accounts/enums';
import { GET_ACCOUNTS } from '@root/modules/accounts/queries/get-accounts.query';
import { accountsSelector } from '@root/modules/accounts/store/accounts.selector';
import { IAccountResponse } from '@root/modules/accounts/types/account';
import { useGetBrokers } from '@root/modules/experts/hooks/use-get-brokers';
import { authSelector } from '@root/shared-files/modules/auth/store';

export const useGetAccounts = (enabled = true) => {
  const userId = useSelector(authSelector.userId);
  const isBalanceFetchedData = useSelector(accountsSelector.isBalanceFetchedData);
  const balancesCurrency = useSelector(accountsSelector.balanceCurrency);
  const { data: supportedBrokers } = useGetBrokers();

  const accounts = useQuery(['accounts'], GET_ACCOUNTS, {
    enabled: !!supportedBrokers?.list?.length && enabled && !!userId,
    staleTime: 1000 * 60 * 10,
  });

  const getAccountsWithCompany = useCallback(
    (data: IAccountResponse[] | void) => {
      return data?.map(({ user, isServiceAccountAssigned, status, ...rest }) => {
        const brokerData = supportedBrokers?.dataByBrokerServerId[rest.brokerServerId];
        const balanceUpdatedFromSocket = isBalanceFetchedData?.[rest.id];
        const symbolsMapping = brokerData?.serviceAccounts?.find((item) => item.id === rest.serviceAccountId)?.symbolsMapping || {};
        const isPending = rest?.isPending || status === AccountStatus.PendingConnect || (rest?.shouldConnect && status !== AccountStatus.Connected);
        const currency = balancesCurrency?.[rest.id];
        const mtType = supportedBrokers?.list?.find((item) => item.id === brokerData?.companyId)?.mtType;

        return {
          ...rest,
          symbolsMapping,
          company: brokerData?.companyName,
          server: brokerData?.serverName,
          companyId: brokerData?.companyId,
          username: user,
          isSignedIn: !!balanceUpdatedFromSocket || (status === AccountStatus.Connected && rest.shouldConnect),
          serviceAccounts: brokerData?.serviceAccounts,
          isServiceAccountAssigned: !!isServiceAccountAssigned,
          isPending,
          status,
          currency,
          mtType,
        } as IAccount; // TODO: Fix interface. The returned object does not conform to the interface.
      });
    },
    [supportedBrokers, isBalanceFetchedData, balancesCurrency],
  );

  const refetch = useCallback(async () => {
    const response = await accounts.refetch();
    return { ...response, data: getAccountsWithCompany(response.data) };
  }, [accounts, getAccountsWithCompany]);

  return { ...accounts, refetch, data: getAccountsWithCompany(accounts.data) };
};
