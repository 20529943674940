import { FC, SVGProps } from 'react';

import { baseIconProps } from './base-icon-props';

export const SadSmileEmoji: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg {...baseIconProps} width='17' height='16' viewBox='0 0 17 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g clipPath='url(#clip0_23969_3166)'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M8.8335 1.33333C5.1516 1.33333 2.16683 4.3181 2.16683 8C2.16683 11.6819 5.1516 14.6667 8.8335 14.6667C12.5154 14.6667 15.5002 11.6819 15.5002 8C15.5002 4.3181 12.5154 1.33333 8.8335 1.33333ZM0.833496 8C0.833496 3.58172 4.41522 0 8.8335 0C13.2518 0 16.8335 3.58172 16.8335 8C16.8335 12.4183 13.2518 16 8.8335 16C4.41522 16 0.833496 12.4183 0.833496 8Z'
          fill='currentColor'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M5.5 5.9987C5.5 5.63051 5.79848 5.33203 6.16667 5.33203H6.33333C6.70152 5.33203 7 5.63051 7 5.9987C7 6.36689 6.70152 6.66536 6.33333 6.66536H6.16667C5.79848 6.66536 5.5 6.36689 5.5 5.9987Z'
          fill='currentColor'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M10.8335 5.9987C10.8335 5.63051 11.132 5.33203 11.5002 5.33203H11.6668C12.035 5.33203 12.3335 5.63051 12.3335 5.9987C12.3335 6.36689 12.035 6.66536 11.6668 6.66536H11.5002C11.132 6.66536 10.8335 6.36689 10.8335 5.9987Z'
          fill='currentColor'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M11.0284 11.4745C9.7716 10.3748 7.89508 10.3748 6.63833 11.4745L6.60568 11.5031C6.32859 11.7455 5.90741 11.7174 5.66496 11.4403C5.4225 11.1632 5.45058 10.7421 5.72767 10.4996L5.76032 10.471C7.51978 8.93153 10.1469 8.93153 11.9064 10.471L11.939 10.4996C12.2161 10.7421 12.2442 11.1632 12.0017 11.4403C11.7593 11.7174 11.3381 11.7455 11.061 11.5031L11.0284 11.4745Z'
          fill='currentColor'
        />
      </g>
      <defs>
        <clipPath id='clip0_23969_3166'>
          <rect width='16' height='16' fill='white' transform='translate(0.833496)' />
        </clipPath>
      </defs>
    </svg>
  );
};
