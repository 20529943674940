import { QueryFunction } from 'react-query';

import { getSubscriptionProducts } from '../services/get-subscription-products.service';
import { SubscriptionProduct } from '../types/subscription-products';

export type GetSubscriptionProductsQueryKey = ['subscription-products'];
export type GetSubscriptionProductsData = SubscriptionProduct[];

export const GET_SUBSCRIPTION_PRODUCTS: QueryFunction<GetSubscriptionProductsData, GetSubscriptionProductsQueryKey> = async ({ queryKey }) => {
  const result = await getSubscriptionProducts();

  if (result.status === 200) {
    return result.payload;
  } else {
    throw new Error(result.payload);
  }
};
