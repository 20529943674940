import { FC, SVGProps } from 'react';

import { baseIconProps } from './base-icon-props';

export const CursorIcon: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg {...baseIconProps} fill='none' viewBox='0 0 17 16' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M2.56017 8.83716C1.73243 8.51879 1.69798 7.36043 2.50535 6.99345L13.4621 2.01311C14.3033 1.63075 15.1686 2.4961 14.7863 3.33728L9.80592 14.294C9.43894 15.1014 8.28058 15.0669 7.96221 14.2392L6.62119 10.7525C6.51962 10.4885 6.31092 10.2798 6.04682 10.1782L2.56017 8.83716Z'
        stroke='currentColor'
        strokeWidth='2'
      />
    </svg>
  );
};
