import dayjs from 'dayjs';

import { useFormikContext } from 'formik';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { CryptoImage } from '@root/modules/crypto-icons/components/crypto-image';
import { TextField } from '@root/shared/form';
import { Table } from '@root/shared/ui/table';
import { Text } from '@root/shared/ui/typography';

import { ResetTwoFaForm } from '../../types/two-fa';

type Props = {
  exchangeNames: { [key: string]: string };
  orders: ResetTwoFaForm['activityQuestions']['orders'];
};

export const OrdersTable: FC<Props> = ({ exchangeNames, orders }) => {
  const { values } = useFormikContext<ResetTwoFaForm>();
  const { t } = useTranslation('two-fa');

  return (
    <div className='w-full'>
      <Table.Wrapper className='!overflow-x-visible'>
        <Table.Head>
          <Table.Row>
            <Table.HeadCellSlim className='pl-0 !w-[120px]'>{t('resetTwoFaModal.activityQuestions.crypto.exchange')}</Table.HeadCellSlim>
            <Table.HeadCellSlim className='pl-0 !w-[120px]'>{t('resetTwoFaModal.activityQuestions.crypto.tradingPair')}</Table.HeadCellSlim>
            <Table.HeadCellSlim className='pl-0 !w-[120px]'>{t('resetTwoFaModal.activityQuestions.crypto.date')}</Table.HeadCellSlim>
            <Table.HeadCellSlim className='pl-0 !w-[120px]'>{t('resetTwoFaModal.activityQuestions.crypto.price')}</Table.HeadCellSlim>
            <Table.HeadCellSlim className='pl-0 !w-[200px]'>{t('resetTwoFaModal.activityQuestions.crypto.orderAmount.label')}</Table.HeadCellSlim>
          </Table.Row>
        </Table.Head>
        <Table.Body>
          {orders.map((order, index) => {
            const orderIndex = values.activityQuestions.orders.findIndex((formOrder) => formOrder.id === order.id);
            return (
              <Table.Row key={index} className='border-t border-white border-opacity-10'>
                <Table.DataCellSlim className='pl-0 mt-2 flex justify-start items-center gap-x-2 w-[120px]'>
                  <CryptoImage width={26} height={26} id={order.exchangeType as string} type='exchanges' wrapperClassName='flex-shrink-0' />{' '}
                  <Text size='sm'>{exchangeNames[order.exchangeType] || order.exchangeType}</Text>
                </Table.DataCellSlim>
                <Table.DataCellSlim className='pl-0 w-[120px]'>{order.pair}</Table.DataCellSlim>
                <Table.DataCellSlim className='pl-0 w-[120px]'>{order.datetime ? dayjs(new Date(order.datetime)).format('DD/MM/YYYY hh:mm:ss A') : '-'}</Table.DataCellSlim>
                <Table.DataCellSlim className='pl-0 w-[120px]'>{order.average}</Table.DataCellSlim>
                <Table.DataCellSlim className='pl-0 w-[200px]'>
                  <TextField
                    name={`activityQuestions.orders[${orderIndex}].amount`}
                    placeholder={t('resetTwoFaModal.activityQuestions.crypto.orderAmount.placeholder')}
                    size='small'
                  />
                </Table.DataCellSlim>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table.Wrapper>
    </div>
  );
};
