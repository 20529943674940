import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';

import { authSelector } from '@root/shared-files/modules/auth/store';

import { GET_TWO_FA_METHODS, GetTwoFaMethodsData, GetTwoFaMethodsQueryKey } from '../queries/get-two-fa-methods.query';

export const useGetTwoFaMethods = () => {
  const user = useSelector(authSelector.user);

  return useQuery<GetTwoFaMethodsData, Error, GetTwoFaMethodsData, GetTwoFaMethodsQueryKey>(['two-fa-methods'], GET_TWO_FA_METHODS, {
    enabled: !!user,
  });
};
