import styled from 'styled-components';

import { ToastContainer as BaseToastContainer } from 'react-toastify';

export const ToastOverlay = styled.div`
  position: fixed;
  z-index: 1010;
  top: 2em;
  right: -350px;
  width: 300px;
  transition: 1s;

  &.--visible {
    right: 1em;
  }
`;

export const ToastWrapper = styled.div<{ type: 'info' | 'success' | 'danger' }>`
  border-radius: 4px;
  padding: 1em 1.5em;
  width: 100%;
  height: 100%;
  z-index: 100000;
  font-family: 'Poppins', sans-serif !important;
  font-size: 14px;

  ${({ type, theme }) => {
    if (type === 'info') {
      return `background-color: ${theme.info[500]}`;
    }
    if (type === 'success') {
      return `background-color: ${theme.success[500]}`;
    }
    if (type === 'danger') {
      return `background-color: ${theme.danger[500]}`;
    }
  }}
`;

export const ToastContainer = styled(BaseToastContainer)`
  --toastify-color-progress-light: ${({ theme }) => theme.gray[100]};
  --toastify-color-progress-info: ${({ theme }) => theme.info[500]};
  --toastify-color-progress-success: ${({ theme }) => theme.success[500]};
  --toastify-color-progress-warning: ${({ theme }) => theme.secondary[200]};
  --toastify-color-progress-error: ${({ theme }) => theme.danger[500]};

  z-index: 100000;

  .Toastify__toast {
    padding: 0;
    background: transparent;
  }

  .Toastify__toast-body {
    padding: 0;
  }

  .Toastify__close-button {
    display: none;
  }
`;
