import { FC, SVGProps } from 'react';

import { baseIconProps } from './base-icon-props';

export const ArrowGoUpIcon: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg {...baseIconProps} width='20' height='21' viewBox='0 0 20 21' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.3604 4.93679C10.3604 4.47655 10.7335 4.10346 11.1937 4.10346L18.6937 4.1035C19.1539 4.1035 19.527 4.47659 19.527 4.93683L19.527 12.7399C19.527 13.2001 19.1539 13.5732 18.6937 13.5732C18.2334 13.5732 17.8603 13.2001 17.8603 12.7399L17.8603 5.77016L11.1937 5.77013C10.7335 5.77012 10.3604 5.39703 10.3604 4.93679Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11.0931 13.4166C10.8672 13.4136 10.6521 13.319 10.4973 13.1544L7.33597 9.79439L1.45774 15.6726C1.1323 15.9981 0.604661 15.9981 0.279224 15.6726C-0.0462133 15.3472 -0.046213 14.8196 0.279224 14.4941L6.76495 8.00839C6.92434 7.849 7.14151 7.76098 7.3669 7.76441C7.59229 7.76785 7.80667 7.86244 7.96113 8.02661L11.1202 11.3842L17.9507 4.50149C18.2749 4.17482 18.8025 4.17281 19.1292 4.497C19.4559 4.8212 19.4579 5.34883 19.1337 5.6755L11.6957 13.1704C11.5365 13.3308 11.319 13.4196 11.0931 13.4166Z'
        fill='currentColor'
      />
    </svg>
  );
};
