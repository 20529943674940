import styled from 'styled-components';

import clsx from 'clsx';
import { AnchorHTMLAttributes, DetailedHTMLProps, FC, HTMLAttributes, PropsWithChildren, ReactNode, forwardRef } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { useGhostLogin } from '@root/shared-files/modules/auth/hooks/use-ghost-login';
import { authSelector } from '@root/shared-files/modules/auth/store';
import { ChevronIcon } from '@root/shared/icons/chevron-icon';
import Link from '@root/shared/ui/common/static-locales-link';
import { Text } from '@root/shared/ui/typography';

const Wrapper: FC<PropsWithChildren & { isOpen?: boolean }> = ({ children, isOpen = false }) => {
  const { isGhostLogin } = useGhostLogin();

  return (
    <header
      className={clsx('fixed top-0 left-0 right-0 h-[72px] bg-gray-800 shadow-2xl flex justify-between items-center pl-0 pr-4 rtl:pr-0 rtl:pl-4 lg:pr-8 z-50', {
        'z-100': isOpen,
        'left-[4px] right-[4px] top-[60px]': isGhostLogin,
      })}
    >
      {children}
    </header>
  );
};

const Tiny: FC<PropsWithChildren> = ({ children }) => {
  return <div className='flex'>{children}</div>;
};

const Logo: FC<PropsWithChildren & { className?: string }> = ({ children, className = '' }) => {
  const isVerified = useSelector(authSelector.isEmailVerified);

  return <div className={clsx('flex flex-col justify-center mt-1 flex-shrink-0 flex-grow-0 pl-4 pr-4', isVerified ? 'md:pl-8' : 'md:pl-24', className)}>{children}</div>;
};

const Extend: FC<PropsWithChildren> = ({ children }) => {
  return <div className='flex lg:items-center justify-between whitespace-nowrap py-2 lg:mx-4 z-50 lg:h-full w-full lg:w-auto items-center overflow-x-auto'>{children}</div>;
};

export const MenuItemWrapper = styled.div<{ active: boolean; darken: boolean }>`
  svg {
    path {
      fill: ${({ theme, active, darken }) => (darken ? theme.gray[500] : active ? theme.primary[400] : theme.gray[100])};
    }
  }

  color: ${({ theme, active }) => (active ? theme.primary[400] : theme.gray[100])};

  &:hover {
    svg {
      path {
        fill: ${({ theme, darken }) => (darken ? theme.gray[500] : theme.primary[400])};
      }
    }

    color: ${({ theme }) => theme.primary[400]};
  }
`;

export const MenuItem: FC<{ children: ReactNode; link?: string; darken?: boolean; onClick?(): void; isActive?: boolean }> = ({
  children,
  link,
  onClick,
  darken = false,
  isActive = false,
}) => {
  const location = useLocation();

  //TODO: router check
  const active = location.pathname === link || isActive;

  const commonProps = {
    active,
    darken,
    className: 'flex items-center gap-3 py-3 pl-4 xl:pl-3 cursor-pointer',
  };

  return link ? (
    <Link href={link} passHref>
      <MenuItemWrapper {...commonProps}>{children}</MenuItemWrapper>
    </Link>
  ) : (
    <MenuItemWrapper {...commonProps} onClick={onClick}>
      {children}
    </MenuItemWrapper>
  );
};

const HeaderLinks: FC<PropsWithChildren> = ({ children }) => {
  return <div className='flex items-center gap-2'>{children}</div>;
};

const StyledHeaderLink = styled.a<{ isActive?: boolean; disabled?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 72px;
  text-align: center;
  position: relative;

  opacity: ${({ disabled }) => disabled && 0.4};
  cursor: ${({ disabled }) => !disabled && 'pointer'};
  color: ${({ isActive, theme }) => isActive && theme.primary['400']};
`;

const NestedStyledHeaderLink = styled(StyledHeaderLink)`
  height: 64px;
`;

interface HeaderLinkProps extends DetailedHTMLProps<AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement> {
  icon: ReactNode;
  isOpen: boolean;
  isActive?: boolean;
  disabled?: boolean;
}

const HeaderLink = forwardRef<HTMLAnchorElement, HeaderLinkProps>(function Link({ icon, title, isActive, isOpen, className, ...props }, ref) {
  return (
    <StyledHeaderLink isActive={isActive} className={className} {...props} ref={ref}>
      <div className='flex items-center gap-4'>
        <div className='text-xl'>{icon}</div>
        {title ? <Text className='!text-[16px]'>{title}</Text> : null}
      </div>
      <div>
        <ChevronIcon
          className={clsx('transform transition-200 text-[10px]', {
            'rotate-180': !isOpen,
          })}
        />
      </div>
    </StyledHeaderLink>
  );
});

const NestedHeaderLink = forwardRef<HTMLAnchorElement, HeaderLinkProps>(function Link({ icon, isActive, title, isOpen, className, ...props }, ref) {
  return (
    <NestedStyledHeaderLink isActive={isActive} className={clsx(className, { 'px-4 !flex-row gap-4 !justify-center': isOpen })} {...props} ref={ref}>
      <div className='text-xl'>{icon}</div>
      {title ? <Text size='sm'>{title}</Text> : null}
    </NestedStyledHeaderLink>
  );
});

const MenuToggle = styled.button`
  font-size: 24px;
  padding: 0 ${({ theme }) => `${theme.spacing(6)}px`};
`;

const LogoText = styled.h1<{ small?: boolean }>`
  font-size: ${({ small }) => (small ? '22px' : '35px')};
  font-weight: bold;
  letter-spacing: -1px;
`;

export const HeaderElements = {
  Wrapper,
  Tiny,
  Extend,
  Logo,
  HeaderLinks,
  HeaderLink,
  NestedHeaderLink,
  MenuToggle,
  LogoText,
};
