import { rgba } from 'polished';
import styled, { DefaultTheme, useTheme } from 'styled-components';

import clsx from 'clsx';
import React, { FC, Key, ReactNode, forwardRef, lazy, useMemo } from 'react';
import { OptionProps as BaseOptionProps, Props as BaseSelectProps, ControlProps, components as DefaultComponents, InputProps, SingleValueProps, StylesConfig } from 'react-select';

import { CheckedIcon } from '@root/shared/icons/checked-icon';
import { LoadingIcon } from '@root/shared/icons/loading-icon';
import { RadioOffIcon } from '@root/shared/icons/radio-off-icon';
import { RadioOnIcon } from '@root/shared/icons/radio-on-icon';
import { UncheckedIcon } from '@root/shared/icons/unchecked-icon';

const BaseSelect = lazy(() => import('react-select'));

export type OptionType = {
  value: Key;
  label: Key | JSX.Element;
  description?: Key | JSX.Element;
  prefix?: ReactNode;
  suffix?: ReactNode;
  toString?(): string;
};
type IsMulti = boolean;

type OptionProps<T> = BaseOptionProps<T> & {
  tiny?: boolean;
  large?: boolean;
};

export interface SelectProps extends BaseSelectProps<any> {
  bordered?: boolean;
  colorful?: boolean;
  large?: boolean;
  tiny?: boolean;
  xs?: boolean;
  square?: boolean;
  forcePlaceholder?: boolean;
  checkedOption?: boolean;
  checkedDescriptionOption?: boolean;
  radioOption?: boolean;
  loading?: boolean;
  customLabel?: string | ReactNode;
  transparent?: boolean;
  forceHideValue?: boolean;
  alignMenuToRight?: boolean;
}

const useSelectStyles = (options: SelectProps = {}) => {
  const theme: DefaultTheme = useTheme();
  const { bordered, square, xs, tiny, large, forceHideValue, alignMenuToRight } = options;

  return useMemo<StylesConfig<OptionType, IsMulti>>(
    () => ({
      container: (base) => {
        return { ...base, width: 'auto' };
      },
      control: (base, props) => {
        let borderColor: string | undefined;
        let boxShadow: string | undefined;
        let opacity: string | undefined;

        if (options.colorful) {
          borderColor = theme.primary['300'];
        } else if (props.isFocused && props.menuIsOpen) {
          borderColor = theme.success['400'];
        }

        if (options.colorful) {
          boxShadow = `0 0 0 1px ${theme.primary['300']}`;
        } else if (props.isFocused && props.menuIsOpen) {
          boxShadow = `0 0 0 1px ${theme.success['400']}`;
        }

        if (props.isDisabled) {
          opacity = '0.3';
        }

        if (options.transparent) {
          borderColor = theme.grayscale[100];
        }

        const baseStyles = {
          fontSize: '13px',
          lineHeight: '24px',
          height: 40,
          minHeight: 40,
        };

        if (tiny) {
          baseStyles.height = 32;
          baseStyles.minHeight = 32;
          baseStyles.fontSize = '12px';
          baseStyles.lineHeight = '20px';
        }

        if (large) {
          baseStyles.height = 50;
          baseStyles.minHeight = 50;
          baseStyles.fontSize = '15px';
          baseStyles.lineHeight = '24px';
        }

        if (xs) {
          baseStyles.height = 24;
          baseStyles.minHeight = 24;
          baseStyles.fontSize = '12px';
          baseStyles.lineHeight = '16px';
        }

        const { height, minHeight, fontSize, lineHeight } = baseStyles;

        return {
          ...base,
          height,
          minHeight,
          borderRadius: square ? 0 : '0.25rem',
          borderWidth: '1px',
          borderStyle: 'solid',
          borderColor: !bordered ? 'transparent' : options.colorful || options.transparent ? borderColor : theme.muted['600'],
          backgroundColor: !bordered ? rgba(theme.muted['100'], 0.1) : 'transparent',
          fontWeight: options.colorful ? 700 : 400,
          boxShadow: !bordered && !options.colorful && !xs ? boxShadow : '',
          opacity: opacity,
          fontSize,
          lineHeight,
          width: '100%',

          '&:hover': {
            borderColor: 'none',
          },

          '&:active': {
            borderColor: bordered ? rgba(theme.gray['100'], 0.1) : 'none',
          },
        };
      },
      valueContainer: (base) => ({
        ...base,
        opacity: 1,
        padding: tiny ? `0 12px 0 12px` : large ? `0 1rem` : '0 16px',
        color: options.colorful ? theme.primary['300'] : theme.muted['100'],
        width: 'auto',
        display: 'flex',
        flexWrap: 'nowrap',
        '& input': {
          width: 0,
        },
      }),
      multiValue: (base) => {
        return {
          ...base,
          ...(forceHideValue ? { display: 'none' } : {}),
          opacity: 1,
          userSelect: 'none',
          minWidth: 'auto',
          backgroundColor: !bordered ? rgba(theme.muted['100'], 0.1) : 'transparent',
        };
      },
      multiValueLabel: (base) => {
        return {
          ...base,
          ...(forceHideValue ? { display: 'none' } : {}),
          color: options.colorful ? theme.primary['300'] : theme.muted['100'],
        };
      },
      multiValueRemove: (base) => {
        return {
          ...base,
          ...(forceHideValue ? { display: 'none' } : {}),
          '&:hover': {
            backgroundColor: !bordered ? rgba(theme.muted['100'], 0.1) : 'transparent',
            color: options.colorful ? theme.primary['300'] : theme.muted['100'],
          },
        };
      },
      singleValue: (base) => ({
        ...base,
        color: options.colorful ? theme.primary['300'] : theme.muted['100'],
        flex: 1,
      }),
      placeholder: (base) => ({
        ...base,
        color: options.colorful ? theme.primary['300'] : theme.muted['100'],
        display: 'block',
        lineHeight: 1.5,
        top: 0,
        transform: 'none',
        position: 'unset',
        textOverflow: 'ellipsis',
        maxWidth: '90%',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        fontSize: 13,
        marginRight: 16,
      }),
      input: (base) => ({
        ...base,
        flexGrow: 10,
        marginLeft: 0,
        color: options.colorful ? theme.primary['300'] : theme.muted['100'],
      }),
      indicatorsContainer: (base, props) => ({
        ...base,
        paddingRight: 8,
        '[class*="indicatorContainer"]': {
          transition: 'transform 0.2s',
          transform: `rotate(${props.selectProps.menuIsOpen ? 180 : 0}deg)`,
          color: options.colorful ? theme.primary['300'] : theme.muted['100'],
          padding: '0',
        },
      }),
      indicatorSeparator: () => ({
        display: 'none',
      }),
      menu: (base) => ({
        ...base,
        backgroundColor: rgba(theme.muted['100'], 0.1),
        borderRadius: '0.25rem',
        overflow: 'hidden',
        zIndex: 53,
        ...(alignMenuToRight && { right: 0 }),

        width: 'max-content',
        minWidth: '100%',
      }),
      menuList: (base) => ({
        ...base,
        scrollbarColor: theme.muted['800'] + ' ' + theme.muted['700'],
        backgroundColor: '#191919',
        '::-webkit-scrollbar': {
          width: '9px',
        },
        '::-webkit-scrollbar-track': {
          background: theme.muted['700'],
        },
        '::-webkit-scrollbar-thumb': {
          background: theme.muted['900'],
        },
        '::-webkit-scrollbar-thumb:hover': {
          background: theme.muted['900'],
        },
      }),
      option: (base) => {
        const fontSize = tiny ? '12px' : large ? '15px' : '13px';
        const padding = tiny ? '6px 12px' : large ? '13px 24px' : '10px 16px';

        return {
          ...base,
          backgroundColor: 'transparent',
          padding,
          fontSize,

          '&:hover': {
            backgroundColor: '#191919',
          },
        };
      },
    }),
    [
      bordered,
      options.colorful,
      forceHideValue,
      options.transparent,
      alignMenuToRight,
      square,
      theme.grayscale,
      theme.muted,
      theme.primary,
      theme.success,
      theme.gray,
      tiny,
      large,
      xs,
    ],
  );
};

const SelectValueWrapper = styled.div`
  display: flex;
  flex: 1 1 100%;
  align-items: center;
`;

const SelectOptionWrapper = styled.div<{ disabled?: boolean; tiny?: boolean; large?: boolean }>`
  display: flex;
  padding: ${({ large }) => (large ? '12px 24px' : '6px 12px')};
  cursor: pointer;
  align-items: center;
  justify-content: space-between;
  font-size: ${({ tiny }) => (tiny ? '12px' : '16px')};

  &:hover {
    background-color: ${({ disabled }) => !disabled && '#3a3a3a'};
  }
`;

const SelectOptionPrefix = styled.div`
  margin-right: 16px;
  //font-size: 20px;

  & > * {
    height: 21px;
  }
`;

const RadioCheckOptionPrefix = styled(SelectOptionPrefix)`
  font-size: 16px;
  margin-right: 16px;
`;

const SelectOptionLabel = styled.div`
  max-width: 190px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  @media screen and (min-width: 640px) {
    max-width: 280px;
    overflow: visible;
  }
`;

const SelectOptionSuffix = styled.div`
  margin-left: 16px;
`;

const CustomLabel = styled.p<{ bgTransparent: boolean }>`
  margin-left: 12px;
  font-weight: 500;
  font-size: 13px;
  line-height: 22px;
  color: ${({ theme, bgTransparent }) => (bgTransparent ? theme.muted[100] : theme.gray[500])};
`;

const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 50px;
`;

const SingleValue: FC<SingleValueProps<OptionType>> = ({ data, innerProps }) => {
  return (
    <SelectValueWrapper {...(innerProps as any)}>
      <div className='flex'>
        {/*{data.prefix && <SelectOptionPrefix>{data.prefix}</SelectOptionPrefix>}*/}
        {data.label && <SelectOptionLabel>{data.label}</SelectOptionLabel>}
      </div>
      {/*{data.suffix && <SelectOptionSuffix>{data.suffix}</SelectOptionSuffix>}*/}
    </SelectValueWrapper>
  );
};

const Option: FC<OptionProps<OptionType>> = ({ data, innerProps, innerRef, isDisabled, tiny, large }) => {
  return (
    <SelectOptionWrapper {...innerProps} disabled={isDisabled} ref={innerRef} tiny={tiny} large={large}>
      <div className='flex'>
        {data.prefix && <SelectOptionPrefix>{data.prefix}</SelectOptionPrefix>}
        {data.label && <SelectOptionLabel className={clsx({ 'text-sm': !large, 'text-gray-500': isDisabled })}>{data.label}</SelectOptionLabel>}
      </div>
      {data.suffix && <SelectOptionSuffix>{data.suffix}</SelectOptionSuffix>}
    </SelectOptionWrapper>
  );
};

const CheckOption: FC<OptionProps<OptionType>> = ({ data, isSelected, innerProps, innerRef, tiny, large }) => {
  return (
    <SelectOptionWrapper {...innerProps} ref={innerRef} className='px-4' tiny={tiny} large={large}>
      <RadioCheckOptionPrefix>{isSelected ? <CheckedIcon /> : <UncheckedIcon />}</RadioCheckOptionPrefix>
      {data.prefix && <SelectOptionPrefix className='mr-2'>{data.prefix}</SelectOptionPrefix>}
      {data.label && <SelectOptionLabel className={clsx({ 'text-sm': !large })}>{data.label}</SelectOptionLabel>}
      {data.suffix && <SelectOptionSuffix className='ml-2'>{data.suffix}</SelectOptionSuffix>}
    </SelectOptionWrapper>
  );
};

const CheckDescriptionOption: FC<OptionProps<OptionType>> = ({ data, isSelected, innerProps, innerRef, tiny, large }) => {
  return (
    <SelectOptionWrapper {...innerProps} ref={innerRef} className='px-4 !justify-start' tiny={tiny} large={large}>
      <RadioCheckOptionPrefix>{isSelected ? <CheckedIcon /> : <UncheckedIcon />}</RadioCheckOptionPrefix>
      {data.prefix && <SelectOptionPrefix className='mr-2'>{data.prefix}</SelectOptionPrefix>}
      {data.label && (
        <SelectOptionLabel className={clsx({ 'text-sm': !large })}>
          <div className='flex flex-col gap-0.5'>
            <span>{data.label}</span>
            {data.description && <span className='opacity-50 font-[12px] leading-[1.35]' dangerouslySetInnerHTML={{ __html: data.description || '' }}></span>}
          </div>
        </SelectOptionLabel>
      )}
      {data.suffix && <SelectOptionSuffix className='ml-2'>{data.suffix}</SelectOptionSuffix>}
    </SelectOptionWrapper>
  );
};

const RadioOption: FC<OptionProps<OptionType>> = ({ data, isSelected, innerProps, large, tiny, innerRef }) => {
  return (
    <SelectOptionWrapper {...innerProps} ref={innerRef} className='px-4' tiny={tiny} large={large}>
      <RadioCheckOptionPrefix>{isSelected ? <RadioOnIcon /> : <RadioOffIcon />}</RadioCheckOptionPrefix>
      {data.prefix && <SelectOptionPrefix className='mr-2'>{data.prefix}</SelectOptionPrefix>}
      {data.label && <SelectOptionLabel className={clsx({ 'text-sm': !large })}>{data.label}</SelectOptionLabel>}
      {data.suffix && <SelectOptionSuffix className='ml-2'>{data.suffix}</SelectOptionSuffix>}
    </SelectOptionWrapper>
  );
};

const HiddenControl: FC<ControlProps<OptionType>> = ({ children, ...props }) => {
  const placeholderStyle = useMemo(() => ({ paddingLeft: 18, marginRight: -10 }), []);
  return (
    <DefaultComponents.Control {...props} className={'bg-transparent !flex-nowrap flex flex-row'}>
      {props.hasValue && !props.selectProps.inputValue && (
        <div className='flex-shrink-1 truncate' style={placeholderStyle}>
          {props.selectProps.placeholder}
        </div>
      )}
      {children}
    </DefaultComponents.Control>
  );
};

const controlStyles = {
  display: 'flex',
  background: 'transparent',
  color: 'white',
  // maxWidth: 300,
};

const CustomLabelControl: FC<ControlProps<OptionType>> = ({ children, ...props }) => {
  const selectProps: any = props.selectProps;
  const loading = selectProps?.loading;

  return (
    <div style={controlStyles}>
      <DefaultComponents.Control {...props}>
        <CustomLabel bgTransparent={selectProps.transparent}>{selectProps?.customLabel}</CustomLabel>
        {loading && (
          <LoadingContainer>
            <LoadingIcon />
          </LoadingContainer>
        )}
        {!loading && children}
      </DefaultComponents.Control>
    </div>
  );
};

const Input: FC<InputProps> = (props) => {
  const extra = { autoComplete: 'off', autofill: 'off' } as object;
  return <DefaultComponents.Input {...props} {...extra} />;
};

export const Select = forwardRef<HTMLDivElement, SelectProps>((props) => {
  const { forcePlaceholder, components, checkedOption, checkedDescriptionOption, radioOption, customLabel, xs, tiny, large } = props;

  const styles = useSelectStyles(props);

  const SelectedOption = useMemo(() => {
    if (checkedOption) {
      return CheckOption;
    }

    if (checkedDescriptionOption) {
      return CheckDescriptionOption;
    }

    if (radioOption) {
      return RadioOption;
    }

    return Option;
  }, [checkedOption, checkedDescriptionOption, radioOption]);

  const Control = useMemo(() => {
    if (customLabel) {
      return CustomLabelControl;
    }

    if (forcePlaceholder) {
      return HiddenControl;
    }

    return DefaultComponents.Control;
  }, [customLabel, forcePlaceholder]);

  return (
    <BaseSelect
      menuPlacement='auto'
      hideSelectedOptions={false}
      menuPosition='absolute'
      placeholder='Select'
      blurInputOnSelect
      components={
        {
          SingleValue,
          Option: (props) => <SelectedOption {...props} tiny={tiny} large={large} xs={xs} />,
          Input,
          Control,
          ...components,
        } as any
      }
      styles={styles as any}
      {...props}
    />
  );
});

Select.displayName = 'Select';
