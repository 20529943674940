import { FC, SVGProps } from 'react';

import { baseIconProps } from './base-icon-props';

export const StackIcon: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg {...baseIconProps} width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0.0599188 11.723C0.212277 11.3878 0.607511 11.2396 0.942698 11.392L8.00016 14.5999L15.0576 11.392C15.3928 11.2396 15.788 11.3878 15.9404 11.723C16.0928 12.0582 15.9446 12.4534 15.6094 12.6058L8.27603 15.9391C8.10075 16.0188 7.89958 16.0188 7.72429 15.9391L0.390961 12.6058C0.0557735 12.4534 -0.0924392 12.0582 0.0599188 11.723Z'
        fill='#FCFCFC'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0.0599188 7.39096C0.212277 7.05577 0.607511 6.90756 0.942698 7.05992L8.00016 10.2679L15.0576 7.05992C15.3928 6.90756 15.788 7.05577 15.9404 7.39096C16.0928 7.72615 15.9446 8.12138 15.6094 8.27374L8.27603 11.6071C8.10075 11.6867 7.89958 11.6867 7.72429 11.6071L0.390961 8.27374C0.0557735 8.12138 -0.0924392 7.72615 0.0599188 7.39096Z'
        fill='#FCFCFC'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7.77218 0.0401377C7.91935 -0.0133792 8.08066 -0.0133792 8.22783 0.0401377L15.5612 2.7068C15.8157 2.79937 15.989 3.03674 15.9995 3.30739C16.01 3.57804 15.8558 3.82816 15.6092 3.94024L8.27587 7.27358C8.10059 7.35325 7.89942 7.35325 7.72414 7.27358L0.390801 3.94024C0.144223 3.82816 -0.0100331 3.57804 0.000508097 3.30739C0.0110493 3.03674 0.184293 2.79937 0.438841 2.7068L7.77218 0.0401377ZM2.42873 3.40196L8 5.93436L13.5713 3.40196L8 1.37604L2.42873 3.40196Z'
        fill='#FCFCFC'
      />
    </svg>
  );
};
