import { FC, PropsWithChildren } from 'react';
import { useSelector } from 'react-redux';

import Disclaimer from '@root/infra/layout/components/disclaimer';
import { useIsDesktop } from '@root/infra/user-agent';
import { WarningBanner } from '@root/modules/experts/components/warning-banner';
import { FeedbackModal } from '@root/modules/user-settings/containers/feedback-modal';
import { userSettingsSelector } from '@root/modules/user-settings/store/user-settings.selector';
import { VerificationReminder } from '@root/shared-files/modules/auth/containers';
import { useGhostLogin } from '@root/shared-files/modules/auth/hooks/use-ghost-login';

import { LayoutElements } from '../components';
import { Header } from './header';
import { Sidebar } from './sidebar';

interface Props {
  extraHeaderHeight?: number;
  hideSocial?: boolean;
  showDisclaimer?: boolean;
}

export const Layout: FC<PropsWithChildren<Props>> = ({ children, extraHeaderHeight, showDisclaimer }) => {
  const isDesktop = useIsDesktop();
  const menuOpened = useSelector(userSettingsSelector.menuOpened);
  const { isGhostLogin } = useGhostLogin();

  return (
    <LayoutElements.SafeArea extraHeaderHeight={extraHeaderHeight} isDesktop={isDesktop} menuOpened={menuOpened} isGhostLogin={isGhostLogin}>
      <Sidebar />
      <Header />
      <LayoutElements.Content>
        {/* <div className='mb-4'>
          <WarningBanner />
        </div> */}
        {children}
        {showDisclaimer && <Disclaimer isFixed extraSpace withLink />}
      </LayoutElements.Content>
      <FeedbackModal />
      <VerificationReminder />
    </LayoutElements.SafeArea>
  );
};
