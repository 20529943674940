import { Selector, createSelector } from '@reduxjs/toolkit';

import { IAppState } from '@root/infra/store';

import { IMaintenanceState } from './maintenance.slice';

const getMaintenance = (state: IAppState) => state.maintenance;
const getIsMaintenanceMode = (maintenance: IMaintenanceState) => maintenance.isMaintenanceMode;

export const isMaintenanceModeSelector = createSelector<[Selector<IAppState, IMaintenanceState>], IMaintenanceState['isMaintenanceMode']>(getMaintenance, getIsMaintenanceMode);
