import { AxiosError } from 'axios';

import { fetcher } from '@root/infra/fetcher';
import type { IHttpResponse } from '@root/shared/utils/http-service';

import { AuthTokensMapper, AuthUserMapper } from '../../mappers';
import { IAuth } from '../../types';
import { TwoFaErrorResponse } from '../../types/two-fa-field-error';

export type SignVerifyResponse = IHttpResponse<200, IAuth> | IHttpResponse<400, string> | IHttpResponse<418, TwoFaErrorResponse>;

export const signInVerify = async (data: { type: string; code: string }): Promise<SignVerifyResponse> => {
  try {
    const response = await fetcher.post('/auth/sign-in/verify-2fa', data);

    return {
      status: 200,
      payload: {
        user: AuthUserMapper.toDomain(response.data.user),
        tokens: AuthTokensMapper.toDomain(response.data),
        userSubscriptionInfo: AuthUserMapper.collectSubscriptionInfo(response.data),
      },
    };
  } catch (e) {
    const error = e as AxiosError;
    console.error(e);

    if (error.isAxiosError && error.response) {
      if (typeof error.response.data.message === 'object') {
        return {
          status: 418,
          payload: error.response.data.message,
        };
      }

      return {
        status: 400,
        payload: error.response.data.message,
      };
    }

    return {
      status: 400,
      payload: 'Bad request',
    };
  }
};
