export const formatIfNegativeAmount = (value: number, moneySign = '$', isCurrencySymbolMissed = false) => {
  let separator = '';

  if (isCurrencySymbolMissed) {
    separator = ' ';
  }

  if (value === 0 || value > 0) {
    return moneySign + separator + value;
  }

  return '-' + moneySign + separator + Math.abs(value);
};
