import { FC, SVGProps } from 'react';

import { baseIconProps } from './base-icon-props';

export const HappySmileEmoji: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg {...baseIconProps} width='17' height='16' viewBox='0 0 17 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g clipPath='url(#clip0_23969_117620)'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M8.66699 1.33333C4.98509 1.33333 2.00033 4.3181 2.00033 8C2.00033 11.6819 4.98509 14.6667 8.66699 14.6667C12.3489 14.6667 15.3337 11.6819 15.3337 8C15.3337 4.3181 12.3489 1.33333 8.66699 1.33333ZM0.666992 8C0.666992 3.58172 4.24871 0 8.66699 0C13.0853 0 16.667 3.58172 16.667 8C16.667 12.4183 13.0853 16 8.66699 16C4.24871 16 0.666992 12.4183 0.666992 8Z'
          fill='currentColor'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M5.3335 5.9987C5.3335 5.63051 5.63197 5.33203 6.00016 5.33203H6.16683C6.53502 5.33203 6.8335 5.63051 6.8335 5.9987C6.8335 6.36689 6.53502 6.66536 6.16683 6.66536H6.00016C5.63197 6.66536 5.3335 6.36689 5.3335 5.9987Z'
          fill='currentColor'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M10.667 5.9987C10.667 5.63051 10.9655 5.33203 11.3337 5.33203H11.5003C11.8685 5.33203 12.167 5.63051 12.167 5.9987C12.167 6.36689 11.8685 6.66536 11.5003 6.66536H11.3337C10.9655 6.66536 10.667 6.36689 10.667 5.9987Z'
          fill='currentColor'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M6.47213 9.50989C7.72888 10.6095 9.60541 10.6095 10.8622 9.50989L10.8948 9.48132C11.1719 9.23887 11.5931 9.26695 11.8355 9.54404C12.078 9.82113 12.0499 10.2423 11.7728 10.4848L11.7402 10.5133C9.98071 12.0528 7.35358 12.0528 5.59412 10.5133L5.56148 10.4848C5.28438 10.2423 5.25631 9.82113 5.49876 9.54404C5.74122 9.26695 6.16239 9.23887 6.43948 9.48132L6.47213 9.50989Z'
          fill='currentColor'
        />
      </g>
      <defs>
        <clipPath id='clip0_23969_117620'>
          <rect width='16' height='16' fill='white' transform='translate(0.666992)' />
        </clipPath>
      </defs>
    </svg>
  );
};
