import styled from 'styled-components';

import { FC } from 'react';

const Wrapper = styled.div`
  display: inline-flex;

  & > span {
    height: 100% !important;
    width: 100% !important;
  }
`;

const ImageCmp = styled.img`
  width: ${({ width }) => width || '100%'};
  height: ${({ height }) => height || '100%'};
`;

export interface ImageProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  wrapperClassName?: string;
  quality?: number;
  objectFit?: 'cover' | 'contain' | 'fill' | 'none' | 'scale-down';
  unoptimized?: boolean;
  layout?: 'responsive' | 'fill' | 'fixed' | 'intrinsic';
  objectPosition?: string;
}

export const Image: FC<ImageProps> = ({ wrapperClassName, ...props }) => {
  return (
    <Wrapper className={wrapperClassName}>
      <ImageCmp {...props} />
    </Wrapper>
  );
};
