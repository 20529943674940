import { FC } from 'react';

import { SkeletonLoader } from '@root/shared/ui/skeleton-loader/skeleton-loader';
import { Table } from '@root/shared/ui/table';

type Props = {
  columns: number;
  rows: number;
};

export const SkeletonTable: FC<Props> = ({ columns, rows }) => {
  return (
    <>
      {Array.from({ length: rows }).map((_, i) => (
        <Table.Row key={i}>
          {Array.from({ length: columns }).map((_, index) => (
            <Table.DataCell key={`${i}-${index}`} className='!py-0'>
              <SkeletonLoader shapes={[{ circle: false, width: '100%', height: 26 }]} />
            </Table.DataCell>
          ))}
        </Table.Row>
      ))}
    </>
  );
};
