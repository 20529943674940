import { AxiosError } from 'axios';

import { fetcher } from '@root/infra/fetcher';
import type { IHttpResponse } from '@root/shared/utils/http-service';

import { TwoFAMethodData } from '../../types/two-fa';

export type ConnectEmailResponse = IHttpResponse<200, { method: TwoFAMethodData }> | IHttpResponse<400, string>;

export const connectEmail = async (email: string): Promise<ConnectEmailResponse> => {
  try {
    const response = await fetcher.post('/auth/2fa/methods/email/connect', { email });

    return {
      status: 200,
      payload: response.data,
    };
  } catch (e) {
    const error = e as AxiosError;
    console.error(e);

    if (error.isAxiosError && error.response) {
      return {
        status: 400,
        payload: error.response.data.message,
      };
    }

    return {
      status: 400,
      payload: 'Bad request',
    };
  }
};
