import React, { FC } from 'react';

import { Toggle, TogglerWrapper } from './toggler.styled';

type Props = {
  checked?: boolean;
  disabled?: boolean;
  alwaysActive?: boolean;
  onChange: (value: boolean) => void;
};

export const Toggler: FC<Props> = ({ checked = false, disabled = false, alwaysActive = false, onChange }) => {
  return (
    <TogglerWrapper active={checked || alwaysActive} onClick={() => (!disabled && onChange ? onChange(!checked) : null)} disabled={disabled}>
      <Toggle active={checked} alwaysActive={alwaysActive} />
    </TogglerWrapper>
  );
};
