import clsx from 'clsx';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { CheckSquareIcon } from '@root/shared/icons/check-square';
import { ErrorSquareIcon } from '@root/shared/icons/error-square';
import { QuestionSquareInfo } from '@root/shared/icons/question-square-info';
import { useCustomErrors } from '@root/shared/ui/toast/use-custom-errors';
import { copyToClipboard } from '@root/shared/utils/helpers/copy-to-clipboard';
import { ToastProps, notify } from '@root/shared/utils/notification';

import { ToastWrapper } from './toast.ui';

type Props = {
  toast: ToastProps;
  data?: Record<string, unknown>;
};

export const ToastCmp: FC<Props> = ({ toast, data }) => {
  const { t } = useTranslation('common');

  const { message, errorId } = useCustomErrors(data);

  const onClick = () => {
    if (errorId) {
      copyToClipboard(errorId);

      notify({
        type: 'success',
        title: t('idCopied'),
      });
    }
  };

  if (!toast) {
    return null;
  }

  return (
    <ToastWrapper type={toast.type} onClick={onClick}>
      <div className='flex items-center'>
        <div className='w-10 h-10 mr-4 flex-shrink-0'>
          {toast.type === 'info' ? <QuestionSquareInfo /> : null}
          {toast.type === 'danger' ? <ErrorSquareIcon /> : null}
          {toast.type === 'success' ? <CheckSquareIcon /> : null}
        </div>
        <div className='text-gray-100 flex flex-col justify-center'>
          <div className={clsx('font-bold text-ellipsis overflow-hidden', { 'mb-2': !!toast.title })}>{toast.title}</div>
          {toast.text ? typeof toast.text === 'object' ? <div dangerouslySetInnerHTML={{ __html: (toast.text as any).content }} /> : <div>{message || toast.text}</div> : null}
          {!!errorId && <div className=''>{t('clickToCopy')}</div>}
        </div>
      </div>
    </ToastWrapper>
  );
};
