import { FC, SVGProps } from 'react';

import { baseIconProps } from './base-icon-props';

export const RoadmapIcon: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg {...baseIconProps} viewBox='0 0 16 16' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.00428 0.00292969C8.43596 0.00292969 8.7859 0.352874 8.7859 0.784553V2.38855C8.7859 2.82023 8.43596 3.17017 8.00428 3.17017C7.5726 3.17017 7.22266 2.82023 7.22266 2.38855V0.784553C7.22266 0.352874 7.5726 0.00292969 8.00428 0.00292969ZM3.95331 2.564C4.05037 2.14337 3.78808 1.7237 3.36745 1.62663C2.94683 1.52957 2.52716 1.79186 2.43009 2.21249L0.0240959 12.6385C-0.0729711 13.0591 0.189323 13.4788 0.609947 13.5758C1.03057 13.6729 1.45024 13.4106 1.54731 12.99L3.95331 2.564ZM12.6412 1.62663C13.0618 1.52957 13.4815 1.79186 13.5786 2.21249L15.9846 12.6385C16.0816 13.0591 15.8193 13.4788 15.3987 13.5758C14.9781 13.6729 14.5584 13.4106 14.4613 12.99L12.0553 2.564C11.9583 2.14337 12.2206 1.7237 12.6412 1.62663ZM8.00483 11.4826L6.05978 14.2554L7.84937 13.8913C7.95227 13.8703 8.05834 13.8703 8.16124 13.8913L9.95037 14.2558L8.00483 11.4826ZM7.0337 10.1448C7.50618 9.47255 8.50344 9.47255 8.97593 10.1448L11.7777 14.1385C12.3964 15.0213 11.6115 16.1897 10.5696 15.9773L8.00512 15.4549L5.43747 15.9773C4.3999 16.1872 3.61035 15.0245 4.23111 14.1402L7.0337 10.1448ZM8.7859 5.59705C8.7859 5.16537 8.43596 4.81543 8.00428 4.81543C7.5726 4.81543 7.22266 5.16537 7.22266 5.59705V7.20105C7.22266 7.63273 7.5726 7.98267 8.00428 7.98267C8.43596 7.98267 8.7859 7.63273 8.7859 7.20105V5.59705Z'
      />
    </svg>
  );
};
