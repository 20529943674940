import { FC, SVGProps } from 'react';

import { baseIconProps } from './base-icon-props';

export const CrownIcon: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg {...baseIconProps} width='16' height='11' viewBox='0 0 16 11' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M15.3846 3.15924C15.3846 2.60305 14.9338 2.15222 14.3776 2.15222C13.8215 2.15222 13.3707 2.60305 13.3707 3.15924C13.3722 3.20015 13.3763 3.24094 13.3829 3.28139L10.4584 4.74364L8.53465 1.99526C9.00599 1.69992 9.14853 1.07849 8.8532 0.607261C8.55798 0.13592 7.93654 -0.00673357 7.4652 0.288601C6.99397 0.583937 6.85132 1.20537 7.14666 1.6766C7.22745 1.8055 7.3363 1.91447 7.4652 1.99526L5.54152 4.74364L2.61702 3.28139C2.62356 3.24094 2.62773 3.20015 2.6293 3.15924C2.63201 2.60305 2.18331 2.15008 1.62724 2.14738C1.07116 2.14467 0.618073 2.59325 0.615369 3.14944C0.61289 3.66 0.992847 4.09156 1.49957 4.15387L2.62942 9.23857V10.5439C2.62942 10.7292 2.77962 10.8795 2.96498 10.8795H13.035C13.2203 10.8795 13.3707 10.7292 13.3707 10.5439V9.23857L14.5004 4.15376C15.0039 4.09291 15.3831 3.66642 15.3846 3.15924ZM14.3776 2.82357C14.563 2.82357 14.7132 2.97389 14.7132 3.15924C14.7132 3.34472 14.563 3.49492 14.3776 3.49492C14.1922 3.49492 14.0419 3.34472 14.0419 3.15924C14.042 2.97389 14.1922 2.82368 14.3776 2.82357ZM7.99998 0.809635C8.18534 0.809635 8.33566 0.959838 8.33566 1.14531C8.33566 1.33067 8.18534 1.48098 7.99998 1.48098C7.81462 1.48098 7.66431 1.33067 7.66431 1.14531C7.66442 0.959951 7.81462 0.809748 7.99998 0.809635ZM5.92531 5.36564L7.99998 2.40203L10.0747 5.36564C10.17 5.50209 10.3509 5.54795 10.4998 5.47347L13.6806 3.88322C13.7308 3.93099 13.7857 3.97359 13.8445 4.01009L12.7658 8.8656H3.23417L2.15548 4.01009C2.2143 3.97347 2.26918 3.93099 2.31932 3.88322L5.50017 5.47347C5.64902 5.54773 5.82987 5.50187 5.92531 5.36564ZM1.28672 3.15924C1.28672 2.97389 1.43692 2.82357 1.62239 2.82357C1.80775 2.82357 1.95807 2.97389 1.95807 3.15924C1.95807 3.34472 1.80775 3.49492 1.62239 3.49492C1.43703 3.49481 1.28683 3.3446 1.28672 3.15924ZM12.6993 10.2082H3.30065V9.53684H12.6993V10.2082Z'
        fill='currentColor'
      />
    </svg>
  );
};
