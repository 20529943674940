import { FC, SVGProps } from 'react';

import { baseIconProps } from './base-icon-props';

export const KnowledgeBaseOutlinedIcon: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg {...baseIconProps} xmlns='http://www.w3.org/2000/svg' width='14' height='16' viewBox='0 0 14 16' fill='currentColor' {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12.0385 1H4.077V10.0001H12.0342L12.0381 10L12.0407 10C12.1884 9.99887 12.3078 9.87878 12.3078 9.73082V1.26924C12.3078 1.12054 12.1872 1 12.0385 1ZM3.077 1H2.03847C1.46494 1 1 1.46494 1 2.03847V10.284C1.30414 10.1036 1.65921 10.0001 2.03847 10.0001H3.077V1ZM0 12.0385V2.03847C0 0.912655 0.912655 0 2.03847 0H3.577H12.0385C12.7395 0 13.3078 0.568257 13.3078 1.26924V9.73082C13.3078 10.2544 12.9907 10.704 12.5381 10.8979V13.0762H12.8071C13.0833 13.0762 13.3071 13.3001 13.3071 13.5762C13.3071 13.8524 13.0833 14.0762 12.8071 14.0762H12.0658C12.0567 14.0767 12.0474 14.077 12.0381 14.077C12.0288 14.077 12.0196 14.0767 12.0104 14.0762H10.2309V15.5008C10.2309 15.6741 10.1412 15.835 9.9938 15.9261C9.84639 16.0172 9.66232 16.0255 9.50733 15.948L8.19246 15.2906L6.8776 15.948C6.72261 16.0255 6.53854 16.0172 6.39113 15.9261C6.24372 15.835 6.15399 15.6741 6.15399 15.5008V14.0762H2.06645C2.05719 14.0767 2.04786 14.077 2.03847 14.077C0.912655 14.077 0 13.1643 0 12.0385ZM7.15424 13.585C7.15419 13.5881 7.15411 13.5912 7.154 13.5943V14.6918L7.96886 14.2844C8.10962 14.214 8.27531 14.214 8.41607 14.2844L9.23093 14.6918V13.6039C9.23043 13.5948 9.23018 13.5855 9.23018 13.5762C9.23018 13.5669 9.23043 13.5577 9.23093 13.5485V12.9239H7.154V13.5582C7.15421 13.5642 7.15432 13.5702 7.15432 13.5762C7.15432 13.5791 7.15429 13.5821 7.15424 13.585ZM10.2309 12.4239V13.0762H11.5381V11.0001H3.57799L3.577 11.0001L3.57601 11.0001H2.03847C1.46494 11.0001 1 11.465 1 12.0385C1 12.6045 1.45271 13.0647 2.01575 13.0767C2.02342 13.0764 2.03114 13.0762 2.03891 13.0762H6.15399V12.4239C6.15399 12.1477 6.37785 11.9239 6.654 11.9239H9.73093C10.0071 11.9239 10.2309 12.1477 10.2309 12.4239Z'
        fill='currentColor'
      />
    </svg>
  );
};
