import { useCallback, useMemo } from 'react';

import { useGetProxyServers } from './use-get-proxy-servers';

export const useProxyServerOptions = () => {
  const { data: proxyServers } = useGetProxyServers();

  const proxyServersOptions = useMemo(() => {
    if (proxyServers) {
      return proxyServers.map((item) => ({ value: item.id, label: item.name }));
    }

    return [];
  }, [proxyServers]);

  const getProxyServer = useCallback(
    (id: string | null) => {
      return proxyServersOptions?.find((item) => item.value === id);
    },
    [proxyServersOptions],
  );

  return {
    proxyServersOptions,
    getProxyServer,
  };
};
