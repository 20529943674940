import { AxiosError } from 'axios';

import { fetcher } from '@root/infra/fetcher';
import { MaintenanceMapper } from '@root/shared-files/modules/maintenance/mappers/maintenance.mapper';
import type { IHttpResponse } from '@root/shared/utils/http-service';

export type GetMaintenanceSettingsResponse = IHttpResponse<200, boolean> | IHttpResponse<400, string>;

export const getMaintenanceMode = async (): Promise<GetMaintenanceSettingsResponse> => {
  try {
    const response = await fetcher.get(`/misc/settigns?groups[]=MAINTENANCE_SETTINGS`);

    return {
      status: 200,
      payload: MaintenanceMapper.toDomain(response.data),
    };
  } catch (e) {
    const error = e as AxiosError;

    if (error.isAxiosError && error.response) {
      return {
        status: 400,
        payload: error?.response?.data.message,
      };
    }

    return {
      status: 400,
      payload: 'Bad request',
    };
  }
};
