import { CaseReducer, PayloadAction, createSlice } from '@reduxjs/toolkit';

import { getDeviceType } from '@root/infra/user-agent';

export type UserAgentState = {
  userAgent: string | null;
  isMobile: boolean;
  isIOS: boolean;
  isSafari: boolean;
};

export namespace UserAgentActions {
  export type Updated = PayloadAction<string>;
}

export type UserAgentSliceReducer = {
  updated: CaseReducer<UserAgentState, UserAgentActions.Updated>;
};

export const userAgentInitialState: UserAgentState = {
  userAgent: null,
  isMobile: false,
  isIOS: false,
  isSafari: false,
};

export const userAgentSlice = createSlice<UserAgentState, UserAgentSliceReducer, 'userAgent'>({
  name: 'userAgent',
  initialState: userAgentInitialState,
  reducers: {
    updated: (state, action) => {
      state.userAgent = action.payload;
      state.isMobile = getDeviceType(action.payload) === 'mobile';
      state.isIOS = /iP(ad|od|hone)/i.test(action.payload);
      state.isSafari = !!action.payload.match(/Version\/[\d.]+.*Safari/);
    },
  },
});
