import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

import { authSelector } from '@root/shared-files/modules/auth/store';

const ProtectedRoute = ({ children }) => {
  const { user } = useSelector(authSelector.main);
  const authed = !!user;

  if (!authed) {
    return <Navigate to={`/sign-in?next=${encodeURIComponent(`${location.pathname}${location.search}`)}`} replace />;
  }

  return children;
};

export default ProtectedRoute;
