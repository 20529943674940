import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Location, useNavigate } from 'react-router-dom';

import { usePrompt } from './use-promt';

export const usePageLeave = (blockWhen = true) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [savedUrl, setSavedUrl] = useState<string>('');
  const [isRoutingAllowed, setRoutingAllowed] = useState<boolean>(false);
  const { t } = useTranslation('common');
  const navigate = useNavigate();

  const onBlock = useCallback((nextLocation: Location) => {
    setRoutingAllowed(true);
    setSavedUrl(nextLocation.pathname + nextLocation.search);
    setIsModalOpen(true);
  }, []);

  usePrompt({
    shouldBlock: !isRoutingAllowed && blockWhen,
    message: t('leaveQuestion'),
    showNativeConfirmation: false,
    beforeUnload: true,
    onBlock,
  });

  const closeModal = () => {
    setIsModalOpen(false);
    setRoutingAllowed(false);
  };

  const handleLeaveClick = () => {
    setIsModalOpen(false);
    setRoutingAllowed(true);

    if (savedUrl) {
      if (savedUrl.includes(window.location.origin) || savedUrl.startsWith('/')) {
        navigate(savedUrl.split(window.location.origin).join(''));
      } else {
        window.location.href = savedUrl;
      }
    }
  };

  const handleLinkClick = useCallback(
    (e: MouseEvent) => {
      let node = e.target as HTMLElement;
      let tagName: string | undefined = (e.target as HTMLElement)?.tagName;
      while (true) {
        if (!node) {
          break;
        }
        if (tagName === 'HTML' || tagName === 'A') {
          break;
        }
        node = node?.parentElement as HTMLElement;
        tagName = node?.tagName;
      }

      if (tagName === 'A') {
        const anchorNode = node as HTMLAnchorElement;
        if (anchorNode?.href?.length && anchorNode?.target !== '_blank' && !anchorNode?.download && blockWhen) {
          setSavedUrl(anchorNode.href);
          setIsModalOpen(true);
          e.preventDefault();
          e.stopPropagation();
        }
      }
    },
    [blockWhen],
  );

  useEffect(() => {
    document.addEventListener('click', handleLinkClick);
    return () => document.removeEventListener('click', handleLinkClick);
  }, [handleLinkClick]);

  return {
    isModalOpen,
    isRoutingAllowed,
    setRoutingAllowed,
    closeModal,
    handleLeaveClick,
  };
};
