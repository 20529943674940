import { createGlobalStyle } from 'styled-components';

import { FC, ReactNode } from 'react';

import { useGhostLogin } from '@root/shared-files/modules/auth/hooks/use-ghost-login';

import { GhostLoginBanner } from '../components/ghost-login-banner';

const GlobalGhostLoginStyles = createGlobalStyle<{ isGhostLogin: boolean }>`
  body {
    margin: 0;
    padding: ${({ isGhostLogin }) => (isGhostLogin ? '3px 4px' : '0')};
    ${({ isGhostLogin, theme }) => (isGhostLogin ? `background: ${theme.gradient[5]}` : '')};

    &::after {
        content: ${({ isGhostLogin }) => (isGhostLogin ? "''" : 'none')};
        position: fixed;
        bottom: 0;
        height: 4px;
        width: 100%;
        background: ${({ isGhostLogin, theme }) => (isGhostLogin ? theme.gradient[5] : 'transparent')};
    }
  }
`;

type Props = {
  children: ReactNode;
};

export const GhostLoginWrapper: FC<Props> = ({ children }) => {
  const { isGhostLogin } = useGhostLogin();

  return (
    <div>
      <GlobalGhostLoginStyles isGhostLogin={isGhostLogin} />
      {isGhostLogin && <GhostLoginBanner />}
      {children}
    </div>
  );
};
