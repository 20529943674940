import clsx from 'clsx';
import { FC, PropsWithChildren, ReactNode, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ReactModal from 'react-modal';

import { CrossIcon } from '@root/shared/icons/cross-icon';
import { Button, ButtonProps } from '@root/shared/ui/button';
import { Title } from '@root/shared/ui/typography';

export type ModalProps = {
  isOpen: boolean;
  className?: string;
  overlayClassName?: string;
  title?: string | ReactNode;
  onCancel?: () => void;
  onOk?: () => void;
  cancelProps?: ButtonProps;
  okProps?: ButtonProps;
  footer?: ReactNode;
  header?: ReactNode;
  hideCrossIcon?: boolean;
  disabledOutSideClick?: boolean;
};

export const Modal: FC<PropsWithChildren<ModalProps>> = ({
  className,
  overlayClassName,
  isOpen,
  title,
  onCancel,
  header,
  footer,
  onOk,
  cancelProps,
  okProps,
  children,
  hideCrossIcon = false,
  disabledOutSideClick = false,
}) => {
  const { t } = useTranslation('common');

  useEffect(() => {
    if (typeof window !== 'undefined') {
      isOpen && window.document.body.classList.add('ReactModal__Body--open');
      return () => {
        window.document.body.classList.remove('ReactModal__Body--open');
      };
    }
  }, [isOpen]);

  return (
    <ReactModal
      className={clsx('bg-gray-800 p-4 w-full max-w-xl outline-none mx-auto my-8 lg:mt-24 relative md:p-8', className)}
      overlayClassName={clsx('fixed z-[1005] lg:z-50 top-0 left-0 right-0 bottom-0 bg-black bg-opacity-70 overflow-auto', overlayClassName)}
      isOpen={isOpen}
      onRequestClose={disabledOutSideClick ? undefined : onCancel}
      appElement={document.getElementById('root') as HTMLElement}
    >
      {header || (
        <div className='-mt-2'>
          {!!title && (
            <Title level={5} bold>
              {title}
            </Title>
          )}
        </div>
      )}
      {!hideCrossIcon && (
        <button className='absolute top-8 right-8 text-lg' onClick={onCancel}>
          <CrossIcon />
        </button>
      )}
      <div className='pt-8'>{children}</div>
      {typeof footer !== 'undefined' ? (
        footer
      ) : (
        <div className='flex justify-between pt-8'>
          <Button onClick={onCancel} outlined {...cancelProps}>
            {cancelProps?.children || t('cancel')}
          </Button>
          <Button onClick={onOk} {...okProps}>
            {okProps?.children || t('ok')}
          </Button>
        </div>
      )}
    </ReactModal>
  );
};
