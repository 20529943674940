export const convertPermissionLimitToHumanReadable = (limit?: number) => {
  if (limit === undefined) {
    return '-';
  }

  if (limit === -1) {
    return '∞';
  }

  return limit;
};
