import { FC, SVGProps } from 'react';

import { baseIconProps } from './base-icon-props';

export const BriefCaseIcon: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg {...baseIconProps} viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g clipPath='url(#clip0_14915_3246)'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M0 5.9987C0 4.52594 1.19391 3.33203 2.66667 3.33203H13.3333C14.8061 3.33203 16 4.52594 16 5.9987V12.6654C16 14.1381 14.8061 15.332 13.3333 15.332H2.66667C1.19391 15.332 0 14.1381 0 12.6654V5.9987ZM2.66667 4.66536C1.93029 4.66536 1.33333 5.26232 1.33333 5.9987V12.6654C1.33333 13.4017 1.93029 13.9987 2.66667 13.9987H13.3333C14.0697 13.9987 14.6667 13.4017 14.6667 12.6654V5.9987C14.6667 5.26232 14.0697 4.66536 13.3333 4.66536H2.66667Z'
          fill='currentColor'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M4.66669 2.66797C4.66669 1.5634 5.56212 0.667969 6.66669 0.667969H9.33335C10.4379 0.667969 11.3334 1.5634 11.3334 2.66797V4.0013H10V2.66797C10 2.29978 9.70154 2.0013 9.33335 2.0013H6.66669C6.2985 2.0013 6.00002 2.29978 6.00002 2.66797V4.0013H4.66669V2.66797Z'
          fill='currentColor'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M0.318306 9.90252C0.318127 9.90241 0.31796 9.90231 0.666637 9.33409C1.01531 8.76588 1.01517 8.76579 1.01504 8.76571C1.01507 8.76573 1.01472 8.76551 1.01504 8.76571L1.01974 8.76855L1.04261 8.78214C1.06391 8.79469 1.09719 8.81402 1.14185 8.83919C1.2312 8.88952 1.36597 8.96308 1.54142 9.05218C1.89259 9.23052 2.40505 9.47025 3.04104 9.71041C4.31704 10.1923 6.06807 10.6674 7.99997 10.6674C9.93186 10.6674 11.6829 10.1923 12.9589 9.71041C13.5949 9.47025 14.1074 9.23052 14.4585 9.05218C14.634 8.96308 14.7687 8.88952 14.8581 8.83919C14.9028 8.81402 14.936 8.79469 14.9573 8.78214L14.9802 8.76855L14.9847 8.7658C14.9846 8.76588 14.9846 8.76588 15.3333 9.33409C15.682 9.90231 15.6818 9.90241 15.6816 9.90252L15.6801 9.90343L15.6771 9.90528L15.6675 9.91111C15.6595 9.91592 15.6483 9.92258 15.6341 9.93096C15.6056 9.94771 15.5649 9.97134 15.5125 10.0009C15.4077 10.0599 15.256 10.1426 15.0623 10.241C14.675 10.4377 14.118 10.6979 13.4299 10.9578C12.0578 11.4759 10.1421 12.0008 7.99997 12.0008C5.85779 12.0008 3.94216 11.4759 2.57001 10.9578C1.88192 10.6979 1.32495 10.4377 0.93769 10.241C0.743922 10.1426 0.592233 10.0599 0.48743 10.0009C0.435016 9.97134 0.394292 9.94771 0.365856 9.93096C0.351637 9.92258 0.340487 9.91592 0.33248 9.91111L0.322836 9.90528L0.319798 9.90343L0.318306 9.90252Z'
          fill='currentColor'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M6.66669 7.9987C6.66669 7.63051 6.96516 7.33203 7.33335 7.33203H8.66669C9.03488 7.33203 9.33335 7.63051 9.33335 7.9987C9.33335 8.36689 9.03488 8.66536 8.66669 8.66536H7.33335C6.96516 8.66536 6.66669 8.36689 6.66669 7.9987Z'
          fill='currentColor'
        />
      </g>
    </svg>
  );
};
